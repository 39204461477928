import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/shared/services/api.service';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatLegacyChipList } from '@angular/material/legacy-chips';

interface Assignment {
    type: string;
    selectedItems?: number[];
    selectedContentIds?: number[];
    dueDate: Date;
    title: string;
    description: string;
    startFrom: Date;
    endOn: Date;
    recurring: {
        enabled: boolean;
        frequency: 'daily' | 'weekly' | 'monthly';
        repeatEvery: number;
        endDate?: Date;
    };
}

interface Module {
    ProgramLessonId: number;
    ChapterName: string;
    Contents: ModuleContent[];
}

interface ModuleContent {
    ContentId: number;
    ContentName: string;
    selected?: boolean;
}

interface ModuleLesson {
    ProgramLessonId: number;
    LessonName: string;
    LessonOrder: number;
    contents: ModuleContent[];
    selected?: boolean;
}

interface ModuleChapter {
    ProgramChapterId: number;
    ChapterName: string;
    ChapterOrder: number;
    lessons: ModuleLesson[];
    selected?: boolean;
}

interface ModuleProgram {
    ProgramId: number;
    ProgramName: string;
    chapters: ModuleChapter[];
    selected?: boolean;
}

interface ParticipantGroup {
    GroupId: number;
    GroupName: string;
    participants: Participant[];
}

interface Participant {
    UserId: number;
    FirstName: string;
    LastName: string;
    GroupId: number;
}

interface ModuleHierarchy {
    ProgramId: number;
    ProgramName: string;
    chapters: {
        ProgramChapterId: number;
        ChapterName: string;
        lessons: {
            ProgramLessonId: number;
            LessonName: string;
            contents: {
                ContentId: number;
                ContentName: string;
            }[];
        }[];
    }[];
}

@Component({
    selector: 'sb-add-assignments-modal',
    templateUrl: './add-assignments-modal.component.html',
    styleUrls: ['./add-assignments-modal.component.scss']
})
export class AddAssignmentsModalComponent implements OnInit {
    @Input() participantId?: number;
    @Input() participant?: any;

    loading = false;
    participants: any[] = [];
    selectedParticipantId?: any[];

    // assignmentTypes = ['Assessment', 'Module', 'Exercise', 'Game Movie', 'Other'];
    assignmentTypes = ['Module', 'Assessment', 'Other'];
    assignments: Assignment[] = [];

    // Cache for items by type
    private itemsCache: { [key: string]: any[] } = {};

    // Add new properties
    moduleContents: { [moduleId: number]: any[] } = {};

    // Add new properties to store processed modules
    private modulesByLesson: { [key: number]: Module } = {};

    // Add these new properties
    participantSearchCtrl = new FormControl('');
    filteredParticipants: any[] = [];
    searchText = '';

    @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
    selectedParticipants: any[] = [];
    isAllSelected = false;
    @ViewChild('chipList') chipList: any;

    currentStep = 1; // Track current step
    participantGroups: ParticipantGroup[] = [];
    moduleHierarchy: ModuleProgram[] = [];
    selectedGroups: number[] = [];

    // Add new properties for module selection
    selectedPrograms: number[] = [];
    selectedChapters: number[] = [];
    selectedLessons: number[] = [];

    // Add new properties for module search
    moduleSearchText: string = '';
    filteredModuleHierarchy: ModuleProgram[] = [];

    constructor(
        private modalController: ModalController,
        private apiService: ApiService,
        private snackbar: MatSnackBar
    ) { }

    ngOnInit() {
        this.loadParticipantGroups();
        this.addNewAssignment(); // Add initial assignment
    }

    async loadParticipantGroups() {
        try {
            const response: any = await this.apiService.getTrainerParticipantsList();
            // Process the flat list into groups
            this.processParticipantGroups(response.data);
        } catch (error) {
            this.snackbar.open('Error loading participants', 'Close', { duration: 3000 });
        }
    }

    processParticipantGroups(data: any[]) {
        const groupMap = new Map<number, ParticipantGroup>();
        
        data.forEach(item => {
            if (!groupMap.has(item.GroupId)) {
                groupMap.set(item.GroupId, {
                    GroupId: item.GroupId,
                    GroupName: item.GroupName,
                    participants: []
                });
            }
            
            groupMap.get(item.GroupId)?.participants.push({
                UserId: item.UserId,
                FirstName: item.FirstName,
                LastName: item.LastName,
                GroupId: item.GroupId
            });
        });

        this.participantGroups = Array.from(groupMap.values());
        this.participants = data.map(item => ({
            UserId: item.UserId,
            FirstName: item.FirstName,
            LastName: item.LastName,
            GroupId: item.GroupId
        }));
        this.filteredParticipants = [...this.participants];
    }

    processModuleHierarchy(data: any[]) {
        // Sort the data first by ChapterOrder and then by ContentOrder
        const sortedData = data.sort((a, b) => {
            if (a.ChapterOrder === b.ChapterOrder) {
                return a.ContentOrder - b.ContentOrder;
            }
            return a.ChapterOrder - b.ChapterOrder;
        });

        const programMap = new Map<number, ModuleProgram>();
        
        sortedData.forEach(item => {
            // Process Program level
            if (!programMap.has(item.ProgramId)) {
                programMap.set(item.ProgramId, {
                    ProgramId: item.ProgramId,
                    ProgramName: item.ProgramName,
                    chapters: [],
                    selected: false
                });
            }
            const program = programMap.get(item.ProgramId)!;

            // Process Chapter level
            let chapter = program.chapters.find(c => c.ProgramChapterId === item.ProgramChapterId);
            if (!chapter) {
                chapter = {
                    ProgramChapterId: item.ProgramChapterId,
                    ChapterName: item.ChapterName,
                    ChapterOrder: item.ChapterOrder,
                    lessons: [],
                    selected: false
                };
                program.chapters.push(chapter);
            }

            // Process Lesson level
            let lesson = chapter.lessons.find(l => l.ProgramLessonId === item.ProgramLessonId);
            if (!lesson) {
                lesson = {
                    ProgramLessonId: item.ProgramLessonId,
                    LessonName: item.LessonName,
                    LessonOrder: item.LessonOrder,
                    contents: [],
                    selected: false
                };
                chapter.lessons.push(lesson);
            }

            // Add Content if it doesn't exist
            const contentExists = lesson.contents.some(c => c.ContentId === item.ContentId);
            if (!contentExists) {
                lesson.contents.push({
                    ContentId: item.ContentId,
                    ContentName: item.ContentName,
                    selected: false
                });
            }
        });

        // Sort chapters by ChapterOrder
        programMap.forEach(program => {
            program.chapters.sort((a, b) => a.ChapterOrder - b.ChapterOrder);

            // Sort lessons by LessonOrder
            program.chapters.forEach(chapter => {
                chapter.lessons.sort((a, b) => a.LessonOrder - b.LessonOrder);

                // Sort contents by ContentOrder
                chapter.lessons.forEach(lesson => {
                    lesson.contents.sort((a, b) => {
                        const aContent = sortedData.find(item => item.ContentId === a.ContentId);
                        const bContent = sortedData.find(item => item.ContentId === b.ContentId);
                        return (aContent?.ContentOrder || 0) - (bContent?.ContentOrder || 0);
                    });
                });
            });
        });

        this.moduleHierarchy = Array.from(programMap.values());
    }

    onGroupSelect(groupId: number) {
        const index = this.selectedGroups.indexOf(groupId);
        if (index > -1) {
            this.selectedGroups.splice(index, 1);
            this.selectedParticipants = this.selectedParticipants.filter(p => p.GroupId !== groupId);
        } else {
            this.selectedGroups.push(groupId);
            const group = this.participantGroups.find(g => g.GroupId === groupId);
            if (group) {
                const newParticipants = group.participants.filter(
                    p => !this.selectedParticipants.some(sp => sp.UserId === p.UserId)
                );
                this.selectedParticipants.push(...newParticipants);
            }
        }
        this.selectedParticipantId = this.selectedParticipants.map(p => p.UserId);
    }

    nextStep() {
        if (this.currentStep === 1 && this.selectedParticipants.length > 0) {
            this.currentStep = 2;
        }
    }

    previousStep() {
        if (this.currentStep === 2) {
            this.currentStep = 1;
        }
    }

    onParticipantChange($event: any) {
        console.log($event);
        console.log(this.selectedParticipantId.length, this.participants.length);

        // if ($event.value.indexOf('0') !== -1) {
        //     this.selectedParticipantId = this.participants.map(p => p.Id);
        // } else {
        //     this.selectedParticipantId = $event.value;
        // }
    }

    selectAllParticipants() {

        if (this.selectedParticipantId.length === this.participants.length) {
            this.selectedParticipantId = [];
        } else {
            this.selectedParticipantId = [0, ...this.participants.map(p => p.Id)];
        }
    }

    addNewAssignment() {
        const startDate = new Date();
        const endDate = new Date();
        const dueDate = new Date();
        
        // Set both end date and due date to 15 days after start date
        endDate.setDate(startDate.getDate() + 15);
        dueDate.setDate(startDate.getDate() + 15);

        this.assignments.push({
            type: '',
            dueDate: dueDate,
            startFrom: startDate,
            endOn: endDate,
            title: '',
            description: '',
            recurring: {
                enabled: false,
                frequency: 'daily',
                repeatEvery: 1,
            }
        });
    }

    removeAssignment(index: number) {
        this.assignments.splice(index, 1);
        if (this.assignments.length === 0) {
            this.addNewAssignment();
        }
    }

    async onTypeChange(index: number) {
        const type = this.assignments[index].type;
        if (!this.itemsCache[type]) {
            try {
                this.loading = true;
                const response = await this.loadItemsByType(type);
                switch (type.toLowerCase()) {
                    case 'assessment':
                        this.itemsCache[type] = response.data.students.map((item: any) => ({
                            id: item.AssessmentId,
                            name: item.Title
                        }));
                        break;
                    case 'module':
                        // Process the module hierarchy
                        this.processModuleHierarchy(response.data);
                        // Store in cache
                        this.itemsCache[type] = this.moduleHierarchy;
                        // Initialize filtered hierarchy
                        this.filteredModuleHierarchy = [...this.moduleHierarchy];
                        break;
                    case 'exercise':
                        this.itemsCache[type] = response.data.map((item: any) => { return { id: item.ExerciseId, name: item.Title } });
                        break;
                    default:
                        // this.itemsCache[type] = response.data.map((item: any) => { return { id: item.id, name: item.name } });
                        break;
                }
            } catch (error) {
                console.log(error);
                this.snackbar.open(`Error loading ${type} items`, 'Close', { duration: 3000 });
            } finally {
                this.loading = false;
            }
        } else if (type.toLowerCase() === 'module') {
            // If module data is already cached, restore the hierarchy
            this.moduleHierarchy = this.itemsCache[type];
            this.filteredModuleHierarchy = [...this.moduleHierarchy];
        }
    }

    private processModuleResponse(data: any[]): { [key: number]: Module } {
        const modules: { [key: number]: Module } = {};
        
        data.forEach(item => {
            if (!modules[item.ProgramLessonId]) {
                modules[item.ProgramLessonId] = {
                    ProgramLessonId: item.ProgramLessonId,
                    ChapterName: item.ChapterName,
                    Contents: []
                };
            }
            
            // Add content if it doesn't already exist
            const contentExists = modules[item.ProgramLessonId].Contents.some(
                c => c.ContentId === item.ContentId
            );
            
            if (!contentExists) {
                modules[item.ProgramLessonId].Contents.push({
                    ContentId: item.ContentId,
                    ContentName: item.ContentName
                });
            }
        });

        this.modulesByLesson = modules;
        return modules;
    }

    getModuleContents(moduleId: number): ModuleContent[] {
        return this.modulesByLesson[moduleId]?.Contents || [];
    }

    getItemsByType(type: string): any[] {
        return this.itemsCache[type] || [];
    }

    private async loadItemsByType(type: string): Promise<any> {
        switch (type.toLowerCase()) {
            case 'assessment':
                return this.apiService.getTrainerAssessments();
            case 'module':
                return this.apiService.getTrainerModules();
            case 'exercise':
                return this.apiService.getTrainerExercises();
            case 'content':
            // return this.apiService.getContent();
            case 'adioscope':
            // return this.apiService.getAdioscope();
            default:
            // return this.apiService.getOtherItems();
        }
    }

    isValid(): boolean {
        const isValid = this.assignments.every(assignment => {
            if (!assignment.type || !assignment.title || !assignment.dueDate || 
                !assignment.startFrom || !assignment.endOn) return false;
            
            // Check if end date is after start date
            if (new Date(assignment.endOn) < new Date(assignment.startFrom)) return false;
            
            switch (assignment.type) {
                case 'Module':
                    // Check if there are selected contents
                    return assignment.selectedContentIds && assignment.selectedContentIds.length > 0;
                case 'Assessment':
                    // Check if an assessment is selected
                    return assignment.selectedItems !== undefined;
                case 'Other':
                    // For 'Other' type, just need the message in selectedItems
                    return !!assignment.selectedItems;
                default:
                    return false;
            }
        }) && this.selectedParticipants.length > 0; // Check if participants are selected
        
        return isValid;
    }

    async saveAssignments() {
        if (!this.isValid()) return;

        let zeroIndex = this.selectedParticipantId.indexOf(0);
        if (zeroIndex > -1) {
            this.selectedParticipantId.splice(zeroIndex, 1);
        }
        this.loading = true;
        console.log({
            participants: this.selectedParticipantId!,
            assignments: this.assignments
        });
        this.apiService.addTrainerNewAssignmentsToParticipants({
            participants: this.selectedParticipantId!,
            assignments: this.assignments
        }).then((response: any) => {
            if (response.success) {
                this.snackbar.open('Assignments saved successfully', 'Close', { duration: 3000 });
                this.modalController.dismiss({ success: true });
            } else {
                throw new Error('Failed to save assignments');
            }
        }).catch((error: any) => {
            this.snackbar.open('Error saving assignments', 'Close', { duration: 3000 });
        });
    }

    back() {
        this.modalController.dismiss();
    }

    // Add new method to handle module selection
    onModuleChange(assignmentIndex: number) {
        const assignment = this.assignments[assignmentIndex];
        // Reset content selection when module changes
        assignment.selectedContentIds = [];
    }

    // Add method to handle select all contents
    selectAllContents(assignmentIndex: number, moduleId: number) {
        const assignment = this.assignments[assignmentIndex];
        const contents = this.getModuleContents(moduleId);
        
        if (!assignment.selectedContentIds || 
            assignment.selectedContentIds.length !== contents.length) {
            assignment.selectedContentIds = contents.map(c => c.ContentId);
        } else {
            assignment.selectedContentIds = [];
        }
    }

    // Add this filter method
    // filterParticipants(event: any) {
    //     const searchText = event.target.value.toLowerCase();
    //     this.filteredParticipants = this.participants.filter(participant =>
    //         `${participant.FirstName} ${participant.LastName}`.toLowerCase().includes(searchText)
    //     );
    // }

    // Update the search text
    onSearchTextChange(event: any) {
        this.searchText = event.target.value;
        this.participantSearchCtrl.setValue(this.searchText);
    }

    // Clear search
    clearSearch() {
        this.searchText = '';
        this.participantSearchCtrl.setValue('');
    }

    addParticipant(event: MatAutocompleteSelectedEvent): void {
        if (!event.option.value) return;
        
        const participant = event.option.value;
        this.toggleParticipant(participant);
        
        this.searchInput.nativeElement.value = '';
        this.participantSearchCtrl.setValue('');
    }

    removeParticipant(participant: any): void {
        const index = this.selectedParticipants.findIndex(p => p.UserId === participant.UserId);
        if (index >= 0) {
            this.selectedParticipants.splice(index, 1);
            this.selectedParticipantId = this.selectedParticipants.map(p => p.UserId);
            
            const groupIndex = this.selectedGroups.indexOf(participant.GroupId);
            if (groupIndex > -1 && !this.selectedParticipants.some(p => p.GroupId === participant.GroupId)) {
                this.selectedGroups.splice(groupIndex, 1);
            }
        }
        this.isAllSelected = false;
    }

    isParticipantSelected(participant: any): boolean {
        return this.selectedParticipants.some(p => p.UserId === participant.UserId);
    }

    toggleSelectAll(): void {
        if (this.isAllSelected) {
            this.selectedParticipants = [];
        } else {
            this.selectedParticipants = [...this.participants];
        }
        this.selectedParticipantId = this.selectedParticipants.map(p => p.UserId);
        this.isAllSelected = !this.isAllSelected;
        this.searchInput.nativeElement.value = '';
        this.participantSearchCtrl.setValue('');
    }

    filterParticipants(event: any): void {
        const searchText = event.target.value.toLowerCase();
        this.filteredParticipants = this.participants.filter(participant =>
            `${participant.FirstName} ${participant.LastName}`
                .toLowerCase()
                .includes(searchText)
        );
    }

    displayFn(participant: any): string {
        return ''; // Return empty string to keep input clear
    }

    onInputFocus(): void {
        // Show all participants when input is focused
        this.filteredParticipants = this.participants;
        this.participantSearchCtrl.setValue('');
    }

    someSelected(): boolean {
        return this.selectedParticipants.length > 0 && 
               this.selectedParticipants.length < this.participants.length;
    }

    toggleParticipant(participant: any): void {
        const index = this.selectedParticipants.findIndex(p => p.UserId === participant.UserId);
        if (index >= 0) {
            this.removeParticipant(participant);
        } else {
            this.selectedParticipants.push(participant);
            this.selectedParticipantId = this.selectedParticipants.map(p => p.UserId);
        }
        this.isAllSelected = this.selectedParticipants.length === this.participants.length;
    }

    // Add methods for handling selection changes
    onProgramSelectionChange(event: any) {
        const selectedProgramIds = event.value;
        
        // Update selected state for all programs
        this.moduleHierarchy.forEach(program => {
            program.selected = selectedProgramIds.includes(program.ProgramId);
            
            // If program is selected, select all its chapters
            if (program.selected) {
                program.chapters.forEach(chapter => {
                    if (!this.selectedChapters.includes(chapter.ProgramChapterId)) {
                        this.selectedChapters.push(chapter.ProgramChapterId);
                        chapter.selected = true;
                    }
                });
            } else {
                // If program is deselected, deselect all its chapters
                program.chapters.forEach(chapter => {
                    const index = this.selectedChapters.indexOf(chapter.ProgramChapterId);
                    if (index > -1) {
                        this.selectedChapters.splice(index, 1);
                        chapter.selected = false;
                    }
                });
            }
        });
        
        this.updateAssignmentSelection();
    }

    onChapterSelectionChange(event: any, index: number) {
        const selectedChapterIds = event.value;
        const currentSelectedItems = this.assignments[index].selectedItems || [];
        
        // Update selected state for all chapters
        this.moduleHierarchy.forEach(program => {
            program.chapters.forEach(chapter => {
                chapter.selected = selectedChapterIds.includes(chapter.ProgramChapterId);
                
                // If chapter is selected, select all its contents
                if (chapter.selected) {
                    chapter.lessons.forEach(lesson => {
                        lesson.contents.forEach(content => {
                            if (!currentSelectedItems.includes(content.ContentId)) {
                                currentSelectedItems.push(content.ContentId);
                                content.selected = true;
                            }
                        });
                    });
                } else {
                    // If chapter is deselected, deselect all its contents
                    chapter.lessons.forEach(lesson => {
                        lesson.contents.forEach(content => {
                            const contentIndex = currentSelectedItems.indexOf(content.ContentId);
                            if (contentIndex > -1) {
                                currentSelectedItems.splice(contentIndex, 1);
                                content.selected = false;
                            }
                        });
                    });
                }
            });
        });
        
        this.assignments[index].selectedItems = currentSelectedItems;
    }

    onContentSelectionChange(event: any, index: number) {
        // const selectedContentIds = event.selectedOptions.selected.map(option => option.value);
        const selectedContentIds = this.assignments[index].selectedContentIds;
        console.log(selectedContentIds);
        if (this.assignments.length > 0 && this.assignments[index].type === 'Module') {
            
            
            // Create a deep copy and update the hierarchy
            this.moduleHierarchy = this.moduleHierarchy.map(program => ({
                ...program,
                chapters: program.chapters.map(chapter => ({
                    ...chapter,
                    lessons: chapter.lessons.map(lesson => ({
                        ...lesson,
                        contents: lesson.contents.map(content => ({
                            ...content,
                            selected: selectedContentIds.includes(content.ContentId)
                        }))
                    }))
                }))
            }));

            // Update filtered hierarchy as well
            this.filteredModuleHierarchy = [...this.moduleHierarchy];
        }
    }

    // Add methods for filtering based on selection
    getFilteredChapters(): ModuleChapter[] {
        const chapters: ModuleChapter[] = [];
        this.moduleHierarchy
            .filter(p => this.selectedPrograms.includes(p.ProgramId))
            .forEach(p => chapters.push(...p.chapters));
        return chapters;
    }

    getFilteredLessons(): ModuleLesson[] {
        const lessons: ModuleLesson[] = [];
        this.getFilteredChapters()
            .filter(c => this.selectedChapters.includes(c.ProgramChapterId))
            .forEach(c => lessons.push(...c.lessons));
        return lessons;
    }

    getFilteredContents(): ModuleContent[] {
        const contents: ModuleContent[] = [];
        this.getFilteredLessons()
            .filter(l => this.selectedLessons.includes(l.ProgramLessonId))
            .forEach(l => contents.push(...l.contents));
        return contents;
    }

    updateAssignmentSelection() {
        if (this.assignments.length > 0 && this.assignments[0].type === 'Module') {
            const selectedContentIds = [];
            this.moduleHierarchy.forEach(program => {
                program.chapters.forEach(chapter => {
                    chapter.lessons.forEach(lesson => {
                        lesson.contents.forEach(content => {
                            if (content.selected) {
                                selectedContentIds.push(content.ContentId);
                            }
                        });
                    });
                });
            });
            this.assignments[0].selectedContentIds = selectedContentIds;
        }
    }

    // Add method to filter module contents
    filterModuleContents() {
        if (!this.moduleSearchText) {
            this.filteredModuleHierarchy = [...this.moduleHierarchy];
            return;
        }

        const searchText = this.moduleSearchText.toLowerCase();
        this.filteredModuleHierarchy = this.moduleHierarchy.map(program => {
            const filteredProgram = { ...program };
            filteredProgram.chapters = program.chapters.map(chapter => {
                const filteredChapter = { ...chapter };
                filteredChapter.lessons = chapter.lessons.map(lesson => {
                    const filteredLesson = { ...lesson };
                    filteredLesson.contents = lesson.contents.filter(content =>
                        content.ContentName.toLowerCase().includes(searchText)
                    );
                    return filteredLesson;
                }).filter(lesson => lesson.contents.length > 0);
                return filteredChapter;
            }).filter(chapter => chapter.lessons.length > 0);
            return filteredProgram;
        }).filter(program => program.chapters.length > 0);
    }

    // Add methods for chapter selection
    isChapterSelected(chapter: ModuleChapter, assignmentIndex: number): boolean {
        const chapterContentIds = this.getAllChapterContentIds(chapter);
        const selectedContentIds = this.assignments[assignmentIndex].selectedItems || [];
        return chapterContentIds.every(id => selectedContentIds.includes(id));
    }

    isChapterIndeterminate(chapter: ModuleChapter, assignmentIndex: number): boolean {
        const chapterContentIds = this.getAllChapterContentIds(chapter);
        const selectedContentIds = this.assignments[assignmentIndex].selectedItems || [];
        const selectedCount = chapterContentIds.filter(id => selectedContentIds.includes(id)).length;
        return selectedCount > 0 && selectedCount < chapterContentIds.length;
    }

    toggleChapter(chapter: ModuleChapter, assignmentIndex: number): void {
        const chapterContentIds = this.getAllChapterContentIds(chapter);
        const allSelected = this.isChapterSelected(chapter, assignmentIndex);
        const currentSelectedItems = this.assignments[assignmentIndex].selectedContentIds || [];

        if (allSelected) {
            // Deselect all contents in the chapter
            this.assignments[assignmentIndex].selectedContentIds = currentSelectedItems.filter(
                id => !chapterContentIds.includes(id)
            );
        } else {
            // Select all contents in the chapter
            const newSelectedItems = [...currentSelectedItems];
            chapterContentIds.forEach(id => {
                if (!newSelectedItems.includes(id)) {
                    newSelectedItems.push(id);
                }
            });
            this.assignments[assignmentIndex].selectedContentIds = newSelectedItems;
        }

        // Update content selected states
        chapter.lessons.forEach(lesson => {
            lesson.contents.forEach(content => {
                content.selected = this.assignments[assignmentIndex].selectedItems?.includes(content.ContentId);
            });
        });
    }

    private getAllChapterContentIds(chapter: ModuleChapter): number[] {
        const contentIds: number[] = [];
        chapter.lessons.forEach(lesson => {
            lesson.contents.forEach(content => {
                contentIds.push(content.ContentId);
            });
        });
        return contentIds;
    }
} 