<div class="ion-page">
    <ion-content>
        <div class="fullscreen beige-space">
            <div class="box" style="max-width: 800px;">
                <div class="back" (click)="back()">
                    <mat-icon>close</mat-icon>
                </div>
                <h1 class="title flex">
                    Add Assignments - Step {{currentStep}} of 2
                    <div class="ml-auto align-self-end pr-4"></div>
                </h1>

                <div class="box-body overflow-auto">
                    <!-- Step 1: Participant Selection -->
                    <ng-container *ngIf="currentStep === 1">
                        <div class="participant-groups w-100">
                            <mat-label>Select Groups</mat-label>
                            <div class="groups-container">
                                <mat-card *ngFor="let group of participantGroups" 
                                         [class.selected]="selectedGroups.includes(group.GroupId)"
                                         (click)="onGroupSelect(group.GroupId)">
                                    <mat-card-header>
                                        <mat-card-title>{{group.GroupName}}</mat-card-title>
                                        <mat-card-subtitle>{{group.participants.length}} participants</mat-card-subtitle>
                                    </mat-card-header>
                                </mat-card>
                            </div>
                        </div>

                        <!-- Individual Participant Selection -->
                        <div class="participant-selection w-100">
                            <mat-label>Selected Participants <span *ngIf="selectedParticipants.length > 0">({{selectedParticipants.length}})</span></mat-label>
                            <mat-form-field appearance="outline" floatLabel="always" class="w-100">
                                <input matInput 
                                       class="participant-input"
                                       [matAutocomplete]="auto"
                                       [formControl]="participantSearchCtrl"
                                       (input)="filterParticipants($event)"
                                       (focus)="onInputFocus()"
                                       placeholder="Search participants..."
                                       #searchInput>
                                
                                <mat-chip-list class="participant-chip-list" #chipList>
                                    <mat-chip *ngFor="let selected of selectedParticipants" 
                                             [removable]="true"
                                             (removed)="removeParticipant(selected)">
                                        {{selected.FirstName}} {{selected.LastName}}
                                        <mat-icon matChipRemove>cancel</mat-icon>
                                    </mat-chip>
                                </mat-chip-list>

                                <mat-autocomplete #auto="matAutocomplete" 
                                                 (optionSelected)="addParticipant($event)"
                                                 [displayWith]="displayFn">
                                    <div class="select-all-option">
                                        <mat-checkbox [checked]="isAllSelected"
                                                    [indeterminate]="someSelected()"
                                                    (change)="toggleSelectAll()"
                                                    (click)="$event.stopPropagation()">
                                            {{ isAllSelected ? 'Unselect All' : 'Select All' }} ({{participants.length}})
                                        </mat-checkbox>
                                    </div>
                                    <mat-option *ngFor="let participant of filteredParticipants" 
                                               [value]="participant">
                                        <mat-checkbox [checked]="isParticipantSelected(participant)"
                                                    (change)="toggleParticipant(participant)"
                                                    (click)="$event.stopPropagation()">
                                            {{participant.FirstName}} {{participant.LastName}}
                                        </mat-checkbox>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>

                        <div class="step-actions w-100">
                            <button mat-raised-button 
                                    color="primary" 
                                    class="btn btn-primary text-white w-100"
                                    [disabled]="selectedParticipants.length === 0"
                                    (click)="nextStep()">
                                Next
                            </button>
                        </div>
                    </ng-container>

                    <!-- Step 2: Assignment Creation -->
                    <ng-container *ngIf="currentStep === 2">

                        <div class="w-100">
                            <div class="selected-participants-count mb-4">
                                <mat-card>
                                    <mat-card-content>
                                        <p>Selected Participants: {{selectedParticipants.length}}</p>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>

                        <div class="assignments-list w-100">
                            <div class="assignment-items">
                                <div class="assignment-item" *ngFor="let assignment of assignments; let i = index">
                                    <mat-card>
                                        <mat-card-content>
                                            <!-- Title -->
                                            <div class="assignment-title">
                                                <!-- Date Range Picker -->
                                                <div class="date-range-picker">
                                                    <mat-label>Assignment Period (Dates From - To)</mat-label>
                                                    <mat-form-field appearance="outline" class="w-100">
                                                        <mat-date-range-input [rangePicker]="rangePicker">
                                                            <input matStartDate
                                                                   placeholder="Start date"
                                                                   [(ngModel)]="assignment.startFrom">
                                                            <input matEndDate
                                                                   placeholder="End date"
                                                                   [(ngModel)]="assignment.endOn">
                                                        </mat-date-range-input>
                                                        <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
                                                        <mat-date-range-picker #rangePicker></mat-date-range-picker>
                                                    </mat-form-field>
                                                </div>

                                                <mat-label>Assignment Title</mat-label>
                                                <mat-form-field appearance="outline" class="w-100" floatLabel="always">
                                                    <input matInput [(ngModel)]="assignment.title"
                                                        placeholder="Assignment Title" required>
                                                </mat-form-field>
                                            </div>

                                            <!-- Description -->
                                            <div class="assignment-description">
                                                <mat-label>Assignment Description</mat-label>
                                                <mat-form-field appearance="outline" class="w-100" floatLabel="always">
                                                    <textarea matInput [(ngModel)]="assignment.description"
                                                        placeholder="Assignment Description" rows="3"></textarea>
                                                </mat-form-field>
                                            </div>

                                            <!-- Type Selection -->
                                            <div class="assignment-type">
                                                <mat-label>Assignment Type</mat-label>
                                                <mat-form-field appearance="outline" floatLabel="always">
                                                    <mat-select [(ngModel)]="assignment.type"
                                                        (selectionChange)="onTypeChange(i)"
                                                        placeholder="Select Assignment Type">
                                                        <mat-option *ngFor="let type of assignmentTypes" [value]="type">
                                                            {{type}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>

                                            <!-- Items Selection based on type -->
                                            <div class="assignment-items" *ngIf="assignment.type">
                                                <ng-container *ngIf="assignment.type == 'Assessment'">
                                                    <mat-label>Select Assessment</mat-label>
                                                    <mat-form-field appearance="outline" class="w-100" floatLabel="always">
                                                        <mat-select [(ngModel)]="assignment.selectedItems"
                                                            [placeholder]="'Select Assessment'">
                                                            <mat-option *ngFor="let item of getItemsByType(assignment.type)"
                                                                [value]="item.id">
                                                                {{item.name}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </ng-container>

                                                <ng-container *ngIf="assignment.type == 'Module'">
                                                    <div class="module-selection">
                                                        <mat-label>Select Contents</mat-label>
                                                        <div class="search-box">
                                                            <input type="text" 
                                                                   placeholder="Search contents..."
                                                                   [(ngModel)]="moduleSearchText"
                                                                   (input)="filterModuleContents()">
                                                        </div>
                                                        <mat-selection-list #contentList 
                                                                          [(ngModel)]="assignment.selectedContentIds"
                                                                          (selectionChange)="onContentSelectionChange($event, i)">
                                                            <div *ngFor="let program of filteredModuleHierarchy" class="program-group">
                                                                <div class="program-name">{{program.ProgramName}}</div>
                                                                <div *ngFor="let chapter of program.chapters" class="chapter-group">
                                                                    <div class="chapter-name">
                                                                        <mat-checkbox [checked]="isChapterSelected(chapter, i)"
                                                                                     [indeterminate]="isChapterIndeterminate(chapter, i)"
                                                                                     (change)="toggleChapter(chapter, i)"
                                                                                     (click)="$event.stopPropagation()">
                                                                            {{chapter.ChapterName}}
                                                                        </mat-checkbox>
                                                                    </div>
                                                                    <ng-container *ngFor="let lesson of chapter.lessons">
                                                                        <mat-list-option *ngFor="let content of lesson.contents"
                                                                                       [value]="content.ContentId"
                                                                                       [selected]="assignment.selectedItems?.includes(content.ContentId)"
                                                                                       checkboxPosition="before">
                                                                            {{content.ContentName}}
                                                                        </mat-list-option>
                                                                    </ng-container>
                                                                </div>
                                                            </div>
                                                        </mat-selection-list>
                                                    </div>
                                                </ng-container>

                                                <ng-container *ngIf="assignment.type == 'Other'">
                                                    <mat-label>Message</mat-label>
                                                    <mat-form-field appearance="outline" class="w-100" floatLabel="always">
                                                        <textarea matInput [(ngModel)]="assignment.selectedItems"
                                                            placeholder="Please enter your message for the participant">
                                                        </textarea>
                                                    </mat-form-field>
                                                </ng-container>
                                            </div>

                                            <!-- Due Date -->
                                            <div class="assignment-due-date">
                                                <mat-label>Due Date</mat-label>
                                                <mat-form-field appearance="outline" class="w-100" floatLabel="always">
                                                    <input matInput [matDatepicker]="picker"
                                                        [(ngModel)]="assignment.dueDate" placeholder="Due Date" required>
                                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                </mat-form-field>
                                            </div>
                                        </mat-card-content>
                                        <mat-card-actions class="d-flex justify-content-end">
                                            <button mat-stroked-button class="ml-2" (click)="removeAssignment(i)">
                                                <mat-icon>delete</mat-icon> Remove Assignment
                                            </button>
                                        </mat-card-actions>
                                    </mat-card>
                                </div>
                            </div>

                            <div class="actions mt-3">
                                <button mat-stroked-button (click)="addNewAssignment()">
                                    <mat-icon>add</mat-icon> Add Another Assignment
                                </button>
                            </div>

                            <div class="step-actions w-100">
                                <button mat-stroked-button (click)="previousStep()">
                                    Back
                                </button>
                                <button mat-raised-button 
                                        color="primary" 
                                        class="btn btn-primary text-white"
                                        [disabled]="!isValid()"
                                        (click)="saveAssignments()">
                                    Send Assignments
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </ion-content>
</div>