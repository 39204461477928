<div class="dialog-container" #dialogContent>
  <div mat-dialog-title class="dialog-title">
    <h2>Log Exercise</h2>
    <div class="back" (click)="onCancel()">
      <mat-icon>close</mat-icon>
    </div>
  </div>

  <mat-tab-group (selectedTabChange)="onTabChange()">
    <mat-tab label="Log Exercise">
      <div style="max-width: 0; max-height: 0; display: none;">
        <input autofocus="false" />
      </div>
      <form [formGroup]="exerciseForm" (ngSubmit)="onSubmit()" autocomplete="off">
        <mat-dialog-content class="dialog-content">
          <div class="form-group">
            <div class="form-group">
              <mat-label>Date</mat-label>
              <mat-form-field appearance="outline" class="full-width">
                <input matInput [matDatepicker]="picker" formControlName="date" [max]="maxDate" [disabled]="true">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker disabled="false"></mat-datepicker>
              </mat-form-field>
            </div>
  
            <div class="form-group">
              <mat-label>Time</mat-label>
              <mat-form-field appearance="outline" class="full-width">
                <input matInput type="time" formControlName="time" autofocus="false">
              </mat-form-field>
            </div>
  
            <mat-label>Exercise Type</mat-label>
            <mat-form-field appearance="outline" class="full-width">
              <mat-select formControlName="exerciseType">
                <mat-option *ngFor="let exercise of predefinedExercises" [value]="exercise">
                  {{exercise}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-label>Where did you do this exercise?</mat-label>
            <mat-form-field appearance="outline" class="full-width">
              <mat-select formControlName="location" (selectionChange)="onLocationChange($event.value)">
                <mat-option *ngFor="let location of predefinedLocations" [value]="location">
                  {{location}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-group" *ngIf="customLocation">
            <mat-label>Where did you do this exercise?</mat-label>
            <mat-form-field appearance="outline" class="full-width">
              <input matInput formControlName="customLocationInput" autofocus="false">
            </mat-form-field>
          </div>


          <div class="form-group">
            <mat-label>Duration (minutes)</mat-label>
            <mat-form-field appearance="outline" class="full-width">
              <mat-select formControlName="duration" (selectionChange)="onDurationChange($event.value)">
                <mat-option *ngFor="let duration of predefinedDurations" [value]="duration">
                  {{duration}} minutes
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- <div class="form-group">
        <mat-label>Custom Duration (minutes)</mat-label>
        <mat-form-field *ngIf="customDuration" appearance="outline" class="full-width">
          <input matInput type="number" formControlName="customDurationInput">
        </mat-form-field>
      </div> -->
        </mat-dialog-content>

        <mat-dialog-actions align="end">
          <button mat-button type="button" (click)="onCancel()">Cancel</button>
          <button class="btn btn-primary text-white" type="submit" [disabled]="exerciseForm.invalid">
            <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
            <span *ngIf="!loading">Log Exercise</span>
          </button>
        </mat-dialog-actions>
      </form>

    </mat-tab>

    <!-- Tab 2: Logs of Last 7 Days -->
    <mat-tab label="Exercise Logs">
      <app-exercise-log-report />
      <!-- <div *ngIf="exerciseSummary" class="exercise-summary-container">
        <div *ngFor="let exerciseType of exerciseTypes">
          <h4>{{ exerciseType }}</h4>
          <div class="exercise-summary-row">
            <div *ngFor="let day of last7Days" class="exercise-summary-row-item">
              <span>{{ getDurationForDay(exerciseType, day) }}</span>
              <span>{{ day | date:'MM/dd' }}</span>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="exerciseLogs" class="exercise-log-container">

        <h3>Exercise Logs for Last 7 Days</h3>
        <p>Exercise Logs for the last 7 days</p>
        <div *ngFor="let log of exerciseLogs" class="exercise-log-row">
          <p>{{ log.ExerciseType }} on {{ log.ExerciseDate | date:'MM/dd/yyyy hh:mm a' }}: {{ log.Duration }} mins</p>
        </div>
      </div> -->
    </mat-tab>
  </mat-tab-group>
</div>