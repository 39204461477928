import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as components from './components';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { IonicModule } from '@ionic/angular';
// import { MatSliderModule } from '@angular/material/slider';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatToolbarModule } from '@angular/material/toolbar';
// import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import {MatBadgeModule} from '@angular/material/badge';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import { HammerModule } from '@angular/platform-browser';

import { MatDialogModule } from '@angular/material/dialog';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { IonicSlides } from '@ionic/angular';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MetronomeService } from './services/metronome.service';
// import { SwiperModule } from 'swiper/angular';

// import { SwiperDirective } from './services/swiper.directive';

// Step 1: Add the following line...
import { register } from 'swiper/element/bundle';
import { SwiperDirective } from './services/swiper.directive';
import { SpeechToTextDirective } from './services/text-to-speech.directive';

import { FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { FilterByIdsPipe } from './pipes/filter-by-ids.pipe';
// Step 2: Add the following line...
register();


@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [...components.components, 
    SpeechToTextDirective,
    SwiperDirective,
    FilterByIdsPipe],
  imports: [
    CommonModule,
    MatProgressBarModule,
    FormsModule,
    MatAutocompleteModule,
    ClipboardModule,
    NgbModule,
    InfiniteScrollModule,
    ReactiveFormsModule,
    HammerModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatIconModule,
    MatCardModule,
    MatSliderModule,
    MatTabsModule,
    MatDatepickerModule,
    MatSelectModule,
    MatBadgeModule,
    IonicModule,
    MatMenuModule,
    MatInputModule,
    MatListModule,
    MatTooltipModule,
    MatChipsModule,
    MatSnackBarModule,
    MatDialogModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatRadioModule,
    MatAutocompleteModule,
  ],
  exports: [...components.components, FilterByIdsPipe],
  providers: []
})
export class SharedModule { }


export const DateTimeValidator = (fc: FormControl) => {
  const date = new Date(fc.value);
  const isValid = !isNaN(date.valueOf());
  return isValid ? null : {
      isValid: {
          valid: false
      }
  };
};
