<div class="ion-page">
    <ion-content>
        <div class="fullscreen beige-space">
            <div class="box" style="max-width: 800px;">
                <div class="back" (click)="back()">
                    <mat-icon>close</mat-icon>
                </div>
                <h1 class="title flex">Exercise Logs
                    <span *ngIf="participant"> {{participant.FirstName}} {{participant.LastName}}</span>
                    <span *ngIf="!participant"> Participants</span>
                    <div class="ml-auto align-self-end pr-4"></div>
                </h1>

                <div class="box-body overflow-auto">

                    <ng-container *ngIf="loading">
                        <div class="row w-100 m-0 flex h-100" style="place-content: center; align-content: center;">
                            <div class="col-md-12 p-0 text-center" style="padding: 5rem;">
                                <p>
                                    <span class="spinner-border spinner-border-lg" role="status"
                                        aria-hidden="true"></span>
                                </p>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!loading">

                        <!-- Participant Selection if not provided -->
                        <div class="participant-selection w-100" *ngIf="!participant">
                            <mat-form-field appearance="outline" floatLabel="always" class="w-100">
                                <mat-select [(ngModel)]="selectedParticipantId"
                                    (selectionChange)="onParticipantChange($event)" multiple
                                    placeholder="Select Participant">
                                    <mat-label>Select Participant</mat-label>
                                    <mat-option (click)="selectAllParticipants()" [value]="0">
                                        <span *ngIf="selectedParticipantId?.length == participants.length + 1">Unselect
                                            All</span>
                                        <span *ngIf="selectedParticipantId?.length != participants.length + 1">Select
                                            All
                                            ({{participants.length}})</span>
                                    </mat-option>
                                    <mat-option *ngFor="let p of participants" [value]="p.Id">
                                        {{p.FirstName}} {{p.LastName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <!-- Assignment List -->
                        <div class="exercise-logs w-100" *ngIf="!loading">
                            <app-exercise-log-report #exerciseLogReport [participant]="selectedParticipantId" />
                        </div>

                    </ng-container>
                </div>
            </div>
        </div>
    </ion-content>
</div>