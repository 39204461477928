<ion-app>
  <ion-router-outlet></ion-router-outlet>
  <!-- <sb-speed-dial-fab></sb-speed-dial-fab> -->

  <!-- <div class="fab-container">
    <button *ngIf="isLoggedIn" class="btn btn-primary fab-button text-white" (click)="openLogExerciseDialog()">
      <mat-icon>add</mat-icon> Log Exercise
    </button>
  </div> -->
</ion-app>
