import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-exercise-log-report',
  templateUrl: './exercise-log-report.component.html',
  styleUrls: ['./exercise-log-report.component.scss']
})
export class ExerciseLogReportComponent {
  @Input() participant: any;

  exerciseSummary: any[] = [];
  exerciseLogs: any[] = [];
  exerciseTypes: any[] = [];
  last7Days: Date[] = [];

  loading: boolean = false;

  get maxDate() {
    return new Date();
  }

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
  ) {
    const currentTime = new Date();
    const formattedTime = currentTime.toLocaleTimeString('en-US', { hour12: false }).substring(0, 5);
  }

  ngOnInit() {
    if (this.participant) {
      let zeroIndex = this.participant.indexOf(0);
      if (zeroIndex > -1) {
        this.participant.splice(zeroIndex, 1);
      }
      console.log(this.participant);
      this.getTrainerExerciseSummaryForLast7Days(this.participant);
    } else {
      this.getExerciseSummaryForLast7Days();
      this.getExerciseLogs();
    }
    this.initializeLast7Days();
  }

  // ngOnChanges() {
  //   if (this.participant) {
  //     this.getTrainerExerciseSummaryForLast7Days(this.participant);
  //   } else {
  //     this.getExerciseSummaryForLast7Days();
  //     this.getExerciseLogs();
  //   }
  //   // this.initializeLast7Days();
  // }

  getTrainerExerciseSummaryForLast7Days(participantId: string) {
    this.apiService.getTrainerExerciseSummaryForLast7Days(participantId).then((res: any) => {
      this.exerciseSummary = res.data.result;
      this.exerciseLogs = res.data.log_results;
      this.exerciseTypes = [...new Set(res.data.result.map((item: any) => item.ExerciseType))];
    });
  }

  getExerciseSummaryForLast7Days() {
    this.apiService.getExerciseSummaryForLast7Days().then((res: any) => {
      this.exerciseSummary = res.data;
      this.exerciseTypes = [...new Set(res.data.map((item: any) => item.ExerciseType))];
    });
  }

  getExerciseLogs() {
    this.apiService.getExerciseLogs({ currentPage: 1, pageSize: 10, totalPages: 1 }).then((res: any) => {
      this.exerciseLogs = res.data.Logs;
    });
  }

  getDurationForDay(exerciseType: string, day: Date): string {
    const entry = this.exerciseSummary.find((item: any) =>
      item.ExerciseType === exerciseType && new Date(item.Date).toDateString() === day.toDateString()
    );
    if (entry && entry.TotalDuration) {
      if (entry.TotalDuration > 60) {
        return Math.floor(entry.TotalDuration / 60) + 'h ' + (entry.TotalDuration % 60) + 'm';
      }
      return entry.TotalDuration + 'm';
    }
    return '0m';
  }

  initializeLast7Days() {
    const today = new Date();
    for (let i = 6; i >= 0; i--) {
      const day = new Date(today);
      day.setDate(today.getDate() - i);
      this.last7Days.push(day);
    }
  }
}