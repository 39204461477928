import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ApiService } from 'src/app/shared/services/api.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-exercise-log-dialog',
  templateUrl: './exercise-log-dialog.component.html',
  styleUrls: ['./exercise-log-dialog.component.scss']
})
export class ExerciseLogDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('dialogContent') dialogContent!: ElementRef;

  exerciseForm: FormGroup;

  exerciseSummary: any[] = [];
  exerciseLogs: any[] = [];
  exerciseTypes: any[] = [];
  last7Days: Date[] = [];

  predefinedExercises = [
    'Focus Dot',
    '5768 Breathing',
    'Peripheral Vision',
    'Other'
  ];

  predefinedLocations = [
    'At home',
    'During a class (eg Math, Bio)',
    'Contemplate Lab (eg Activity Period)',
    'Pre Practice',
    'During Practice',
    'Pre Game',
    'During Game',
    'Other'
  ];

  predefinedDurations = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30
  ];

  customLocation: boolean = false;
  customDuration: boolean = false;

  loading: boolean = false;

  get maxDate() {
    return new Date();
  }
  userId: any;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ExerciseLogDialogComponent>
  ) {
    const currentTime = new Date();
    const formattedTime = currentTime.toLocaleTimeString('en-US', { hour12: false }).substring(0, 5);

    this.exerciseForm = this.fb.group({
      exerciseType: ['', Validators.required],
      location: ['', Validators.required],
      customLocationInput: [''],
      duration: ['', Validators.required],
      date: [new Date(), Validators.required],
      time: [formattedTime, Validators.required]
    });

    // Remove default focus when dialog opens
    this.dialogRef.afterOpened().subscribe(() => {
      // Remove focus from any active element
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    });
  }

  ngOnInit() {
    this.getExerciseSummaryForLast7Days();
    this.getExerciseLogs();
    this.initializeLast7Days();
  }

  ngAfterViewInit() {
    // Scroll to top when dialog opens
    if (this.dialogContent) {
      this.dialogContent.nativeElement.scrollTop = 0;
    }
  }

  getExerciseSummaryForLast7Days() {
    this.apiService.getExerciseSummaryForLast7Days().then((res: any) => {
      this.exerciseSummary = res.data;
      this.exerciseTypes = [...new Set(res.data.map((item: any) => item.ExerciseType))];
    });
  }

  getExerciseLogs() {
    this.apiService.getExerciseLogs({ currentPage: 1, pageSize: 10, totalPages: 1 }).then((res: any) => {
      this.exerciseLogs = res.data.Logs;
    });
  }

  getDurationForDay(exerciseType: string, day: Date): string {
    const entry = this.exerciseSummary.find((item: any) =>
      item.ExerciseType === exerciseType && new Date(item.Date).toDateString() === day.toDateString()
    );
    if (entry && entry.TotalDuration) {
      if (entry.TotalDuration > 60) {
        return Math.floor(entry.TotalDuration / 60) + 'h ' + (entry.TotalDuration % 60) + 'm';
      }
      return entry.TotalDuration + 'm';
    }
    return '0m';
  }

  initializeLast7Days() {
    const today = new Date();
    for (let i = 6; i >= 0; i--) {
      const day = new Date(today);
      day.setDate(today.getDate() - i);
      this.last7Days.push(day);
    }
  }


  onLocationChange(value: string) {
    this.customLocation = value === 'Other';
    if (!this.customLocation) {
      this.exerciseForm.get('customLocationInput')?.setValue('');
    }
  }

  onDurationChange(value: number) {
    this.customDuration = value === 0;
    if (!this.customDuration) {
      this.exerciseForm.get('customDurationInput')?.setValue('');
    }
  }


  // Add method to handle tab changes
  onTabChange() {
    // Scroll to top when changing tabs
    if (this.dialogContent) {
      this.dialogContent.nativeElement.scrollTop = 0;
    }
  }

  onSubmit() {
    if (this.exerciseForm.valid) {

      if (this.loading) return;

      this.loading = true;

      const formValue = this.exerciseForm.value;
      const date = formValue.date;
      const time = formValue.time.split(':');
      const exerciseDateTime = new Date(date);
      exerciseDateTime.setHours(parseInt(time[0], 10));
      exerciseDateTime.setMinutes(parseInt(time[1], 10));
      // Convert to UTC
      const exerciseDateTimeUTC = new Date(exerciseDateTime.getTime() - (exerciseDateTime.getTimezoneOffset() * 60000));

      const result = {
        exerciseType: formValue.exerciseType,
        location: this.customLocation ? formValue.customLocationInput : formValue.location,
        duration: this.customDuration ? formValue.customDurationInput : formValue.duration,
        exerciseDate: exerciseDateTimeUTC
      };

      this.apiService.logExercise(result).then((res) => {
        this.loading = false;
        this.snackBar.open('Exercise logged successfully', 'Close', { duration: 3000 });
        this.dialogRef.close(result);
      }).catch((err) => {
        this.loading = false;
        console.error(err);
      });
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}