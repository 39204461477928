<mat-card class="assignment-card" [class.pending]="type === 'pending'" [class.completed]="type === 'completed'"
    [class.overdue]="type === 'overdue'" *ngIf="assignment">
    <mat-card-header>
        <mat-card-title>{{assignment.Title}}</mat-card-title>
        <mat-card-subtitle>{{assignment.Type}}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
        <div class="assignment-meta">
            <ng-container *ngIf="type === 'completed'">
                <div class="meta-item due-date completed">
                    <mat-icon>event</mat-icon>
                    <span>Completed: {{assignment.CompletedOn | date:'mediumDate'}} by
                        {{assignment.CompletedBy.FirstName}} {{assignment.CompletedBy.LastName}}</span>
                </div>
                <div class="meta-item w-100">
                    <mat-icon>notes</mat-icon>
                    <span *ngIf="assignment.ParticipantRemarks">Participant Notes:
                        {{assignment.ParticipantRemarks}}</span>
                    <span *ngIf="assignment.TrainerRemarks">Trainer Notes: {{assignment.TrainerRemarks}}</span>
                </div>
            </ng-container>
            <ng-container *ngIf="type !== 'completed'">
                <div class="meta-item due-date pending">
                    <mat-icon>event</mat-icon>
                    <span *ngIf="assignment.StartFrom">Starts From: {{assignment.StartFrom | date:'mediumDate'}}</span>
                    <span *ngIf="assignment.EndOn">Ends On: {{assignment.EndOn | date:'mediumDate'}}</span>
                    <span *ngIf="assignment.DueDate">Due: {{assignment.DueDate | date:'mediumDate'}}</span>
                </div>
                <div class="meta-item">
                    <mat-icon>schedule</mat-icon>
                    <span>Assigned: {{assignment.AssignedOn | date:'mediumDate'}}</span>
                </div>

                <ng-container *ngIf="assignment.Recurring">
                    <div class="meta-item">
                        <mat-icon>repeat</mat-icon>
                        <span>{{assignment.RecurringFrequency}} (Every
                            {{assignment.RecurringRepeatEvery}} days)</span>
                    </div>
                </ng-container>
            </ng-container>
        </div>

        <mat-card-subtitle>{{assignment.Type}}</mat-card-subtitle>

        <div class="type-specific-info" *ngIf="assignment.Type === 'Assessment' && assignment.Assessment">
            <h4>{{assignment.Assessment.Title}}</h4>

            <button *ngIf="!isTrainer && assignment.AssessmentSubmission.Status != 'COMPLETED'"
                (click)="viewAssignment(assignment)" class="btn btn-default bg-white text-dark" type="button">
                Start Assessment
            </button>
        </div>

        <div class="type-specific-info" *ngIf="assignment.Type === 'Module' && assignment.Content">
            <h4>{{assignment.Content.Text }}</h4>
            <button class="btn btn-default bg-white text-dark d-flex" (click)="viewAssignment(assignment)" type="button">
                <mat-icon>play_circle</mat-icon>
            </button>
        </div>
        <!-- <p class="description">{{assignment.Description}}</p> -->

    </mat-card-content>

    <mat-card-actions align="end"
        *ngIf="type !== 'completed' && (isTrainer || assignment.Type !== 'Assessment' && assignment.Type !== 'Module')">
        <button mat-button color="primary" [disabled]="markingComplete" (click)="markComplete(assignment)">
            <ng-container *ngIf="!markingComplete">
                <mat-icon>check_circle</mat-icon> Mark Complete
            </ng-container>
            <ng-container *ngIf="markingComplete">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </ng-container>
        </button>
    </mat-card-actions>
</mat-card>