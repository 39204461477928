
<ng-container *ngIf="loading">
    <div class="row w-100 m-0 flex h-100" style="place-content: center; align-content: center;">
        <div class="col-md-12 p-0 text-center" style="padding: 5rem;">
            <p>
                <span class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
            </p>
        </div>
    </div>
</ng-container>

<div class="participant-detail" *ngIf="!loading && participant">
    <!-- Participant Header -->
    <div class="participant-header" *ngIf="isTrainer">
        <h2>{{ participant?.FirstName || '' }} {{ participant?.LastName || '' }}</h2>
        <p class="program-name">{{ participant?.ProgramName || 'No Program Name' }}</p>

        <div class="program-stats">
            <div class="stat">
                <span class="label">Joined On:</span>
                <span class="value">{{ participant.JoinedOn | date }}</span>
            </div>
            <div class="stat">
                <span class="label">Days Elapsed:</span>
                <span class="value">{{ daysElapsed }}</span>
            </div>
            <div class="stat">
                <span class="label">Days Remaining:</span>
                <span class="value">{{ daysRemaining }}</span>
            </div>
            <div class="stat">
                <span class="label">Program Progress:</span>
                <span class="value">{{ programProgress | number:'1.0-0' }}%</span>
            </div>
        </div>
    </div>

    <!-- Assignment Summary -->
    <div class="assignment-summary">
        <div class="summary-header">
            <h3>Assignments</h3>
            <div class="d-flex flex-1"></div>
            <button *ngIf="isTrainer" (click)="openGradingAssignmentDialog()"
              class="btn btn-default bg-white mr-2 text-dark" type="button">
              Grade Participant
            </button>
            <button (click)="openAddAssignmentDialog()"
              class="btn btn-default bg-white text-dark" type="button">
              Add Assignments
            </button>
        </div>
        <div class="assignment-stats">
            <div class="stat">
                <span class="label">Total:</span>
                <span class="value">{{ progress.totalAssignments }}</span>
            </div>
            <div class="stat completed">
                <span class="label">Completed:</span>
                <span class="value">{{ progress.completedAssignments }}</span>
            </div>
            <div class="stat pending">
                <span class="label">Pending:</span>
                <span class="value">{{ progress.pendingAssignments }}</span>
            </div>
            <div class="stat overdue">
                <span class="label">Overdue:</span>
                <span class="value">{{ progress.overdueAssignments }}</span>
            </div>
        </div>
    </div>

    <div class="assignment-lists">

        <div class="assignment-section overdue" *ngIf="assignments?.overDue?.length > 0">
            <h4>Overdue Assignments</h4>
            <div class="assignment-list">
                <ng-container *ngFor="let assignment of assignments.overDue">
                    <sb-assignment-card [isTrainer]="isTrainer" [type]="'overdue'" [assignment]="assignment" (viewAssignmentTrigger)="viewAssignmentTrigger(assignment)" (refreshTrigger)="refreshAssignments()"></sb-assignment-card>
                </ng-container>
            </div>
        </div>

        <div class="assignment-section pending" *ngIf="assignments?.pending?.length > 0">
            <h4>Pending Assignments</h4>
            <div class="assignment-list">
                <ng-container *ngFor="let assignment of assignments.pending">
                    <sb-assignment-card [isTrainer]="isTrainer" [type]="'pending'" [assignment]="assignment" (viewAssignmentTrigger)="viewAssignmentTrigger(assignment)" (refreshTrigger)="refreshAssignments()"></sb-assignment-card>
                </ng-container>
            </div>
        </div>

        <div class="assignment-section completed" *ngIf="assignments?.completed?.length > 0">
            <h4>Completed Assignments</h4>
            <div class="assignment-list">

                <ng-container *ngFor="let assignment of assignments.completed">
                    <sb-assignment-card [isTrainer]="isTrainer" [type]="'completed'" [assignment]="assignment" (viewAssignmentTrigger)="viewAssignmentTrigger(assignment)" (refreshTrigger)="refreshAssignments()"></sb-assignment-card>
                </ng-container>

            </div>
        </div>
    </div>