<!-- Exercise Summary -->
<div *ngIf="exerciseSummary" class="exercise-summary-container">
  <div *ngFor="let exerciseType of exerciseTypes">
    <h4>{{ exerciseType }}</h4>
    <div class="exercise-summary-row">
      <div *ngFor="let day of last7Days" class="exercise-summary-row-item">
        <span>{{ getDurationForDay(exerciseType, day) }}</span>
        <span>{{ day | date:'MM/dd' }}</span>
      </div>
    </div>
  </div>
</div>

<!-- Exercise Logs -->
<div *ngIf="exerciseLogs && exerciseLogs.length > 0" class="exercise-log-container">
  <h3>Exercise Logs for Last 7 Days</h3>
  <p>Exercise Logs for the last 7 days</p>
  <div *ngFor="let log of exerciseLogs" class="exercise-log-row">
    <p *ngIf="log.Name">{{ log.Name }}</p>
    <p>{{ log.ExerciseType }} on {{ log.ExerciseDate | date:'MM/dd/yyyy hh:mm a' }}: {{ log.Duration }} mins</p>
  </div>
</div>

<div *ngIf="exerciseLogs && exerciseLogs.length === 0" class="exercise-log-container">
  <p>No exercise logs found for the last 7 days</p>
</div>
