import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Event, NavigationStart, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _moment from 'moment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { any, any } from 'chart.js';
import { MatMenuTrigger } from '@angular/material/menu';
import { ApiService } from 'src/app/shared/services/api.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AddAssignmentsModalComponent } from '../add-assignments-modal/add-assignments-modal.component';
import { ModalController } from '@ionic/angular';
import { MarkCompleteDialogComponent } from '../mark-complete-dialog/mark-complete-dialog.component';
import { AddGradingAssignmentsModalComponent } from '../add-grading-assignment-modal/add-grading-assignment-modal.component';
import { CookieService } from 'ngx-cookie-service';
import { AudioService } from '../../services/audio.service';
import { Subscription } from 'rxjs';

interface ParticipantDetail {
    Id: number;
    ProgramId: number;
    UserId: number;
    BatchId: number;
    ProgramName: string;
    FirstName: string;
    LastName: string;
    JoinedOn: string;
    CompletedOn: string;
    IsCompleted: boolean;
    OtherTrainers: any[];
}

interface Assignment {
    completed: any[];
    pending: any[];
    overDue: any[];
}

@Component({
    selector: 'sb-participant-assignment-detail',
    templateUrl: './participant-assignment-detail.component.html',
    styleUrls: ['./participant-assignment-detail.component.scss'],
})
export class ParticipantAssignmentDetailComponent implements OnInit {

    // @ViewChild('conversationbox') conversationbox!: ElementRef;

    @Input() isTrainer: boolean = false;
    @Input() participantId: any;
    @Input() batchId: any;
    @Input() programId: any;

    moment = _moment;
    shown_tab: any = 0;

    loading: boolean = true;
    participant?: ParticipantDetail;
    assignments: Assignment = {
        completed: [],
        pending: [],
        overDue: []
    };
    assessments: any[] = [];

    // Initialize progress object
    progress: any = {
        totalAssignments: 0,
        completedAssignments: 0,
        pendingAssignments: 0,
        overdueAssignments: 0,
        completionRate: 0
    };
    // Calculate these based on participant data
    daysRemaining: number = 0;
    programProgress: number = 0;

    isSubscribed: boolean = false;
    loadingContent: boolean = false;
    completing: boolean = false;
    currentFile: any;
    state: any;

    programParticipant: any;
    currentWeekFill: any = [];

    chapterProgress: any = {};
    lessonProgress: any = {};
    contentProgress: any = {};

    currentChapter?: number;
    currentLesson?: number;

    currentChapterContent: any = {};
    currentLessonContent: any = {};

    assignmentValues = {};
    topicValues: any = {};
    submittingTopic: any = {};
    assignmentTopics: any = [];

    assignmentsBackLog: any = [];
    currentWeeksAssignments: any = [];
    recurringAssignmentTopics: any = [];


    participants: any = [];
    batch: any = {};
    daysElapsed: number = 0;
    audiosToListen: number = 0;
    regularityOf: number = 0;

    endingWeek: boolean = false;
    modalsetup: boolean = false;
    createTopicsetup: boolean = false;
    modalloading: boolean = false;
    savingAssignments: boolean = false;

    allAssignmentTopics: any = [];
    programLessonsWithAssignments: any = [];

    trainerVideos: any = [];
    weeksDayOnes: any = [];
    assignmentsToAdd: any = [];
    newAssignmentTopic: any = {};
    changingStatus: any = {};
    dateOffset: any;

    newAssignmentForm?: FormGroup = new FormGroup({
        Name: new FormControl('', Validators.compose([Validators.required])),
        Action: new FormControl('', Validators.compose([Validators.required])),
        Type: new FormControl('', Validators.compose([Validators.required])),
        Recurring: new FormControl('', Validators.compose([Validators.required])),
    });

    currentTrack: any;
  
    trackSub: Subscription;
    audioSub: Subscription;
    progressSub: Subscription;
  
    constructor(
        public _formBuilder: FormBuilder,
        public route: ActivatedRoute,
        private apiService: ApiService,
        public userService: UserService,
        private snackbar: MatSnackBar,
        public dialog: MatDialog,
        public modalService: NgbModal,
        private modalCtrl: ModalController,
        private audioService: AudioService,
        private cookieService: CookieService,
        private router: Router
    ) {

        var offset = new Date().getTimezoneOffset();
        if (offset > 0) {
            this.dateOffset = "-" + ('0' + Math.floor((offset / 60))).slice(-2) + "" + ('0' + Math.floor((offset % 60)).toFixed(0)).slice(-2);
        } else {
            this.dateOffset = "+" + ('0' + Math.floor(((offset * -1) / 60)).toFixed(0)).slice(-2) + "" + ('0' + Math.floor(((offset * -1) % 60)).toFixed(0)).slice(-2);
        }
        this.progressSub = this.audioService.getProgress().subscribe(progress => {
          this.progress = progress;
        });
    
        this.trackSub = this.audioService.getCurrentTrack().subscribe(track => {
          console.log("Current Track is: ", this.currentTrack);
          this.currentTrack = track;
        });
    
        // this.audioSub = this.audioService.getState().subscribe(t => {
        //   this.state = t;
        //   if(this.state.remainingTime < 15) {
        //     this.markAssignmentAsCompleted(this.currentTrack);
        //   }
        // });
    
    }

    ngOnDestroy() {
      this.progressSub.unsubscribe();
      this.trackSub.unsubscribe();
      // this.audioSub.unsubscribe();
    }

    ngOnInit() {
        // this.programId = this.route.snapshot.params["programId"];
        // this.batchId = this.route.snapshot.params["batchId"];
        // this.programParticipantId = this.route.snapshot.params["programParticipantId"];
        this.loading = true;
        this.getBatchParticipant();
    }

    back() {
        this.router.navigateByUrl('/dashboard/trainer');
    }

    changedTab(event: any) {
        console.log(event);
        this.showTab(event.detail.value);
    }

    showTab(index: any) {
        this.shown_tab = index;
        const queryParams: any = { t: index };
        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: queryParams,
                queryParamsHandling: 'merge', // remove to replace all query params by provided
            });
    }
    getBatchParticipant() {
        this.apiService.getTrainerBatchParticipant(this.batchId, this.participantId)
            .then((res: any) => {
                if (res.success && res.data) {
                    // Safely assign participant data
                    this.participant = res.data.participant || null;

                    // Safely assign assignments with default empty arrays
                    this.assignments = {
                        completed: res.data.assignments?.completed || [],
                        pending: res.data.assignments?.pending || [],
                        overDue: res.data.assignments?.overDue || []
                    };

                    this.assessments = res.data.assessments || [];
                    console.log(this.assignments);
                    // Calculate days remaining only if participant exists
                    if (this.participant?.JoinedOn && this.participant?.CompletedOn) {
                        try {
                            const joinedDate = new Date(this.participant.JoinedOn);
                            const completionDate = new Date(this.participant.CompletedOn);
                            const today = new Date();

                            const totalDays = Math.ceil((completionDate.getTime() - joinedDate.getTime()) / (1000 * 3600 * 24));
                            const daysElapsed = Math.ceil((today.getTime() - joinedDate.getTime()) / (1000 * 3600 * 24));

                            this.daysRemaining = Math.max(0, totalDays - daysElapsed);
                            this.daysElapsed = Math.max(0, daysElapsed);

                            // Calculate simple progress percentage
                            this.programProgress = Math.min(100, (daysElapsed / totalDays) * 100);
                        } catch (error) {
                            console.error('Error calculating dates:', error);
                            this.daysRemaining = 0;
                            this.daysElapsed = 0;
                            this.programProgress = 0;
                        }
                    }

                    // Safely calculate assignment statistics
                    try {
                        const totalAssignments =
                            (this.assignments.completed?.length || 0) +
                            (this.assignments.pending?.length || 0) +
                            (this.assignments.overDue?.length || 0);

                        this.progress = {
                            totalAssignments,
                            completedAssignments: this.assignments.completed?.length || 0,
                            pendingAssignments: this.assignments.pending?.length || 0,
                            overdueAssignments: this.assignments.overDue?.length || 0,
                            completionRate: totalAssignments > 0 ?
                                ((this.assignments.completed?.length || 0) / totalAssignments) * 100 : 0
                        };
                    } catch (error) {
                        console.error('Error calculating assignment statistics:', error);
                        // Reset progress to default values if calculation fails
                        this.progress = {
                            totalAssignments: 0,
                            completedAssignments: 0,
                            pendingAssignments: 0,
                            overdueAssignments: 0,
                            completionRate: 0
                        };
                    }
                } else {
                    this.snackbar.open("No data found", "Close", { duration: 3000 });
                }
            })
            .catch(error => {
                console.error('Error fetching participant data:', error);
                const errorMsg = error?.msg || "Something went wrong";
                this.snackbar.open(errorMsg, "Close", { duration: 3000 });
            })
            .finally(() => {
                this.loading = false;
            });
    }

    viewAssignment(assignment: any) {
        if (assignment.Type === 'Assessment') {
            // Navigate to assessment view
            this.router.navigate(['/trainer/assessment', assignment.Assessment.AssessmentId]);
        } else if (assignment.Type === 'Module') {
            // Navigate to module view
            this.router.navigate(['/trainer/module', assignment.ProgramLesson.Id]);
        }
    }

    playingAssignment: any;
    viewAssignmentTrigger(assignment: any) {
        if (assignment.Type == 'Assessment') {
            return;
        } else if (assignment.Type == 'Module') {
          console.log(assignment);
          this.apiService.getAssignmentContent(assignment.Id).then((res: any) => {
            if (res.success) {
              console.log(res.data);
              res.data.content.AssignmentId = assignment.Id;
              this.playingAssignment = assignment;
              Object.keys(res.data.cookies).forEach((cookie) => {
                this.cookieService.delete(res.data.cookies[cookie].Key);
                this.cookieService.set(res.data.cookies[cookie].Key, res.data.cookies[cookie].Value, {
                  domain: ".adiosworld.com",
                  path: "/",
                  secure: true,
                  sameSite: 'None',
                });
              });
              this.audioService.setPlayer('assignment', res.data.content, [res.data.content], 0);
            }
          });
        }
    }

    async openAddAssignmentDialog() {
        console.log('openAddAssignmentDialog');

        var modal = await this.modalCtrl.create({
            component: AddAssignmentsModalComponent,
            canDismiss: true,
            componentProps: {
                participantId: this.participantId,
                batchId: this.batchId,
                programId: this.programId,
                participant: this.participant
            }
        });
        modal.present();
        modal.onDidDismiss().then((res: any) => {
            this.getBatchParticipant();
        }).catch(e => {
            console.log(e);
        });
    }

    async openGradingAssignmentDialog() {
        console.log('openGradingAssignmentDialog');

        var modal = await this.modalCtrl.create({
            component: AddGradingAssignmentsModalComponent,
            canDismiss: true,
            componentProps: {
                participantId: this.participantId,
                batchId: this.batchId,
                programId: this.programId,
                participant: this.participant
            }
        });
        modal.present();
        modal.onDidDismiss().then((res: any) => {
            this.getBatchParticipant();
        }).catch(e => {
            console.log(e);
        });
    }

    refreshAssignments() {
        this.getBatchParticipant();
    }

}
