import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';
import { CommonService } from './common.service';
import { WebHttpService } from './http/web-http.service';
import { NativeHttpService } from './http/native-http.service';

const median: any = (arr: any) => {
    const mid = Math.floor(arr.length / 2),
        nums = [...arr].sort((a, b) => a - b);
    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};
@Injectable({
    providedIn: 'root',
})
export class ApiService {
    domain: any;

    apis = {
        validateToken: 'api/Auth/ValidateToken',
        Login: 'api/Auth/Login',
        serviceLogin: 'api/Auth/ServiceLogin',
        externalLogin: 'api/Auth/ExternalLogin',
        externalLoginApple: 'api/Auth/ExternalAppleLoginApp',
        googleLogin: 'api/Auth/GoogleLogin',
        Register: 'api/Auth/Register',
        ResetPassword: 'api/Auth/ResetPassword',
        ResendVerification: 'api/Auth/ResendVerification',
        VerifyEmail: 'api/Auth/VerifyEmail',
        VerifyResetPassword: 'api/Auth/VerifyResetPassword',
        getPaymentDetails: 'api/Subscription/ProcessApplication/',
        getOnboardingdata: 'api/Setup/GetOnboardingData',

        unsubscribe: 'api/User/Unsubscribe/',

        getAssessment: 'api/Assessment/GetAssessment/',
        submitAssessment: 'api/Assessment/SubmitAssessment/',
        saveAssessmentSubmission: 'api/Assessment/SaveAssessmentSubmission/',

        getDashboard: 'api/Dashboard/GetDashboard',
        getPublicPrograms: 'api/Program/GetPublicPrograms',

        createCoreOrderAndroid: 'api/Payment/CreateCoreOrderAndroid',
        coreOrderAndroidApproved: 'api/Payment/CoreOrderAndroidApproved',
        coreOrderAndroidAcknowledged: 'api/Payment/CoreOrderAndroidAcknowledged',

        getUserDetails: 'api/Dashboard/GetUserDetails',

        scheduleContemplationSession: 'api/ContemplationSession/ScheduleSessions',

        getPrograms: 'api/Program/GetPrograms',
        getProgram: 'api/Program/GetProgram/',
        getPublicProgram: 'api/Program/GetPublicProgram/',

        getApplicationData: 'api/Batches/GetApplications',
        SetupUser: 'api/Setup/SetupUser',
        GetBatches: 'api/Batches/GetBatches/',
        ChangePassword: 'api/User/ChangePassword',
        NewPassword: 'api/User/SetPassword',

        manageSubscriptions: 'api/User/CustomerPortal',

        //User
        enrollBatch: 'api/User/JoinBatch/',
        useAffiliationCode: 'api/User/UseAffiliationCode/',
        requestAccountDeletion: 'api/User/RequestAccountDeletion/',
        upgradeTrial: 'api/User/UpgradeTrial/',
        createOrganisation: 'api/User/CreateOrganisation/',
        getOrganisationUsers: 'api/User/GetOrganisationUsers/',
        inviteUserInOrganisation: 'api/User/InviteUsers/',
        removeUserFromOrganisation: 'api/User/RemoveUserFromOrganisation/',
        removeInviteFromOrganisation: 'api/User/RemoveInviteFromOrganisation/',
        getSubscriptions: 'api/User/GetSubscriptions/',
        cancelSubscription: 'api/User/CancelSubscription/',
        saveBasicProfile: 'api/User/SaveBasicProfile/',
        getBasicProfile: 'api/User/GetBasicProfile',
        setFcmToken: 'api/User/SetFcmToken',
        setAuthCode: 'api/User/SetAuthCode',

        getUserSubscription: 'api/User/GetUserSubscription',
        subscribeToMembership: 'api/User/SubscribeToMembership/',

        addFavorite: 'api/User/AddFavorite/',
        removeFavorite: 'api/User/RemoveFavorite/',
        getFavorites: 'api/User/GetFavorites/',

        getContentNotes: 'api/User/GetContentNotes/',

        logExercise: 'api/User/LogExercise/',
        getExerciseSummaryForLast7Days: 'api/User/GetExerciseSummaryForLast7Days/',
        getExerciseLogs: 'api/User/GetExerciseLogs/',

        //V2 Apis
        getCategoryContentV2: 'api/Category/GetCategoryContentV2/',
        getProgramDetailsV2: 'api/Category/GetProgramContentV3/',
        // getProgramDetailsV2: 'api/Category/GetProgramContentV4/',
        getContentListV2: 'api/Category/GetContentListV2/',
        getLiveCohorts: 'api/Category/GetLiveCohorts/',
        startProgram: 'api/Category/StartProgram/',

        //V3 Apis
        getCategoryContentV3: 'api/Category/GetCategoryContentV3/',
        getProgramDetailsV3: 'api/Category/GetProgramContentV3/',
        getContentListV3: 'api/Category/GetContentListV3/',

        getPersonalAdioscope: 'api/AdioscopeV2/GetPersonal',
        getClearedAdioscope: 'api/AdioscopeV2/GetCleared',
        addSituationV2: 'api/AdioscopeV2/AddSituation',
        addBulkSituations: 'api/AdioscopeV2/AddBulkSituations',
        getSituationAnalysis: 'api/AdioscopeV2/GetSituationAnalysis/',
        deleteSituation: 'api/AdioscopeV2/DeleteSituation/',
        updateSituationDescription: 'api/AdioscopeV2/UpdateSituationDescription/',
        updateSituationPrincipleTrigger: 'api/AdioscopeV2/UpdateSituationPrincipleTrigger',
        updateSituationPrincipleMap: 'api/AdioscopeV2/UpdateSituationPrincipleMap',
        changeSituationStateV2: 'api/AdioscopeV2/ChangeSituationState/',
        setSituationImportanceV2: 'api/AdioscopeV2/SetSituationImportance/',

        sendSituationLoveV2: 'api/AdioscopeV2/SendSituationLove/',

        //Adioscope        
        getPublicAdioscope: 'api/Adioscope/GetPublic',
        getAdioscopeMeta: 'api/Adioscope/getAdioscopeMeta',
        addSituation: 'api/Adioscope/AddSituation',
        getSituation: 'api/Adioscope/GetSituation/',
        viewSituation: 'api/Adioscope/GetSituationDetail/',
        changeSituationPrivacy: 'api/Adioscope/ChangeSituationPrivacy/',
        updateSituationClarity: 'api/Adioscope/UpdateSituationClarity/',
        changeSituationState: 'api/Adioscope/ChangeSituationState/',

        //Venus Match
        getVenusDetails: 'api/Venus/GetDetails',

        //Forum (Ask Us)
        getForumQuestions: 'api/Forum/GetQuestions',
        getForumQuestion: 'api/Forum/GetQuestion/',
        saveForumQuestion: 'api/Forum/SaveQuestion/',

        //Program Workspace
        getProgramSpace: 'api/Workspace/GetProgramSpace/',
        getProgramContent: 'api/Workspace/GetProgramContent/',
        streamAudioContent: 'api/Workspace/StreamAudioContent/',
        streamVideoContent: 'api/Workspace/StreamVideoContent/',
        getProfileQuestions: 'api/Workspace/GetProfileQuestions/',
        updateProfileQuestionMeta: 'api/Workspace/UpdateProfileQuestionMeta/',
        getContentQuestions: 'api/Workspace/GetContentQuestions/',
        updateContentQuestionMeta: 'api/Workspace/UpdateContentQuestionMeta/',
        submitContentQuestionMetaForReview: 'api/Workspace/SubmitContentQuestionMetaForReview/',

        uploadContentVideoFile: 'api/Workspace/UploadContentVideoFile/',

        contentCompleted: 'api/Workspace/ContentCompleted/',

        addEventLog: 'api/User/AddEvent/',


        //Playlist
        getPlaylists: 'api/Library/GetPlaylists/',
        getPlaylistContents: 'api/Library/GetPlaylistContent/',

        streamAudioLibrary: 'api/Library/StreamAudioLibrary/',
        libraryContentCompleted: 'api/Library/LibraryContentCompleted/',


        //Contemplations
        getGuidedContemplations: 'api/Contemplation/GetGuidedContemplations/',
        getGuidedContemplation: 'api/Contemplation/GetGuidedContemplation/',

        streamAudioContemplation: 'api/Contemplation/StreamAudioContemplation/',
        contemplationCompleted: 'api/Contemplation/ContemplationCompleted/',

        //Contemplations
        getPublicFeed: 'api/Community/GetPublicFeed/',
        getPersonalFeed: 'api/Community/GetPersonalFeed/',
        getPost: 'api/Community/GetPost/',
        savePost: 'api/Community/CreatePost/',
        deletePost: 'api/Community/DeletePost/',
        likePost: 'api/Community/LikePost/',
        getComments: 'api/Community/GetComments/',
        saveComment: 'api/Community/CreateComment/',
        deleteComment: 'api/Community/DeleteComment/',

        //Conversations
        getConversation: 'api/Conversation/GetConversation/',
        sendMessage: 'api/Conversation/SendMessage/',
        getMessages: 'api/Conversation/GetMessages/',
        updateLastReadOn: 'api/Conversation/UpdateLastReadOn/',
        approveSubmission: 'api/Conversation/ApproveSubmission/',

        //Web
        getProgramDetails: 'api/Web/GetProgramDetails/',
        getSignupData: 'api/Web/GetSignupData',
        submitCategoryForm: 'api/Web/SubmitCategoryForm/',
        joinVenusPartnerDiscoveryWaitlist: 'api/Web/JoinVenusPartnerDiscoveryWaitlist',

        //Payment
        getPlans: 'api/payment/GetPricingTable',
        subscribeToPlan: 'api/payment/SubscribeToPlan?price_id=',
        validatePlan: 'api/payment/ValidatePlan',

        getOrderDetails: 'api/payment/GetDetails',
        getVenusOrderDetails: 'api/payment/GetVenusOrderDetails',
        getCoreOrderDetails: 'api/payment/GetCoreOrderDetails',
        validateOrder: 'api/payment/ValidateOrder',
        validateSubscription: 'api/payment/ValidateSubscription',
        processJoiningBatch: 'api/payment/ProcessJoiningBatch',
        processUpgradeTrial: 'api/payment/ProcessUpgradeTrial',
        upgradeCoreTrial: 'api/payment/UpgradeTrial/',

        //Journal
        getNotes: 'api/Journal/GetNotes',
        getUserJournal: 'api/Journal/GetJournal',
        addToJournal: 'api/Journal/AddToJournal',
        addUserTag: 'api/Journal/AddUserTag',

        //Get Categories
        checkCategoryMembership: 'api/Category/CheckCategoryMembership/',
        getCategoryMembership: 'api/Category/GetMembership/',
        getCategorySubscription: 'api/Category/GetCategorySubscription/',
        subscribeMembership: 'api/Category/SubscribeMembership/',
        getCategoryWorkspace: 'api/Category/GetCategoryWorkspace/',
        getProfileGroups: 'api/Category/GetProfileGroups/',
        joinCategoryBatch: 'api/Category/JoinCategoryBatch/',
        getOrganisationMembership: 'api/Category/GetOrganisationMembership/',
        getCategoryUpcomingCohorts: 'api/Category/GetUpcomingCohorts/',
        getCategoryProgram: 'api/Category/GetCategoryProgram/',
        getCategoryContent: 'api/Category/GetCategoryContent/',

        getCategoryToday: 'api/Category/GetToday/',


        getPendingInvites: 'api/User/GetPendingInvites/',
        markIntroduced: 'api/User/MarkIntroduced',
        acknowledgeAdioscope: 'api/User/AcknowledgeAdioscope',
        agreeTerms: 'api/User/AgreeTerms',
        markOrganisationIntroduced: 'api/User/MarkOrganisationIntroduced/',
        getIntroProgram: 'api/User/GetIntroProgram/',

        RecommendUsers: 'api/User/RecommendUsers',

        // getUserSubscription: 'api/User/GetUserSubscription',

        getCenter: 'api/Center/GetCenter/',
        getCenterProgram: 'api/Center/GetCenterProgram/',
        SubmitApplication: 'api/Batches/SubmitApplication',
        getBatchSchedule: 'api/Program/GetBatchSchedule/',
        getBatchReport: 'api/Report/GetBatchReport/',
        uploadContentFile: 'api/Program/UploadContentFile/',
        // streamAudioContent: 'api/Program/StreamAudioContent/',
        // streamVideoContent: 'api/Program/StreamVideoContent/',
        downloadContentFile: 'api/Program/DownloadContentFile/',
        deleteContentFile: 'api/Program/DeleteContentFile/',

        getOverDueAssignments: 'api/Assignments/GetOverDueAssignments/',
        getPendingAssignments: 'api/Assignments/GetPendingAssignments/',
        getCompletedAssignments: 'api/Assignments/GetCompletedAssignments/',
        markAssignmentAsCompleted: 'api/Assignments/MarkAssignmentAsCompleted/',
        getAssignmentContent: 'api/Assignments/GetAssignmentContent/',
        getEligibleAssessments: 'api/Assessment/GetEligibleAssessments/',

        addValueToAssignment: 'api/Program/AddValueToAssignment/',
        addValueToDateAssignment: 'api/Program/AddValueToDateAssignment/',
        getParticipantAssignmentTopics: 'api/Program/GetParticipantAssignmentTopics/',
        addSituationInProgram: 'api/Program/AddSituationInProgram/',
        getSituationDetail: 'api/Program/GetSituationDetail/',
        addAnalysisInSituation: 'api/Program/AddAnalysisInSituation/',
        getChangeAssignmentStatus: 'api/Program/ChangeAssignmentStatus/',
        getContemplationItems: 'api/Program/GetContemplationItems',
        startAdiosession: 'api/Program/StartAdiosession',
        joinBatch: 'api/Batches/JoinBatch',
        joinProgram: 'api/Batches/JoinProgram/',
        getAdiosession: 'api/Program/GetAdiosession/',
        startContemplation: 'api/Program/StartContemplation/',
        stopContemplation: 'api/Program/StopContemplation/',
        Subscribe: 'api/Subcription/CreateCheckout',
        ValidateOrder: 'api/Subcription/ValidateOrder',
        getAcapSubscriptions: 'api/Acap/GetSubscriptions',
        subscribeToAcap: 'api/Acap/Subscribe',
        validateAcapSubscription: 'api/Acap/ValidateAcapSubscription',


        //Vision Planner
        getVisionPlanner: 'api/VisionPlanner/GetPlanner/',
        setupPlanner: 'api/VisionPlanner/SetupPlanner/',
        savePlanner: 'api/VisionPlanner/SavePlanner/',
        previewPlanner: 'api/VisionPlanner/PreviewPlanner',
        saveDimensions: 'api/VisionPlanner/SaveDimensions/',
        getVersionDetails: 'api/VisionPlanner/GetVersionDetails/',
        saveDimension: 'api/VisionPlanner/SaveDimension/',
        saveArea: 'api/VisionPlanner/SaveArea/',
        saveTask: 'api/VisionPlanner/SaveTask/',
        removeTask: 'api/VisionPlanner/RemoveTask/',
        removeArea: 'api/VisionPlanner/RemoveArea/',
        removeDimension: 'api/VisionPlanner/RemoveDimension/',

        getDimensions: 'api/VisionPlanner/GetDimensions/',
        addCharacter: 'api/VisionPlanner/AddCharacter/',
        saveCharacterVision: 'api/VisionPlanner/SaveCharacterVision/',
        addAspiration: 'api/VisionPlanner/AddAspiration/',
        addAspirationResult: 'api/VisionPlanner/AddAspirationResult/',
        addAspirationResultTask: 'api/VisionPlanner/AddAspirationResultTask/',

        //Trainers
        getTrainerPrograms: 'api/Trainer/GetPrograms',
        getTrainerParticipants: 'api/Trainer/GetTrainerParticipants',
        getTrainerBatchParticipant: 'api/Trainer/GetBatchParticipant/',
        getTrainerBatchParticipants: 'api/Trainer/GetBatchParticipants/',
        getTrainerExercises: 'api/Trainer/GetTrainerExercises',
        getTrainerModules: 'api/Trainer/GetTrainerModulesList',
        getTrainerAssessments: 'api/Trainer/GetTrainerAssessmentsList',
        getTrainerParticipantsList: 'api/Trainer/getTrainerParticipantsList',
        addTrainerNewAssignmentsToParticipants: 'api/Trainer/AddNewAssignmentsToParticipants/',
        trainerMarkAssignmentAsCompleted: 'api/Trainer/TrainerMarkAssignmentAsCompleted/',
        createTrainerAssessmentSubmission: 'api/Trainer/CreateAssessmentSubmission/',
        getTrainerExerciseSummaryForLast7Days: 'api/Trainer/GetExerciseSummaryForLast7Days',

        getTrainerEndBatchWeek: 'api/Trainer/EndWeek/',
        createNewAssignmentTopic: 'api/Trainer/NewAssignmentTopic/',
        addAssignmentsToBatch: 'api/Trainer/AddAssignmentsToBatch/',
        publishAssignmentsToBatch: 'api/Trainer/PublishAssignmentsToBatch/',
        getTrainerChangeAssignmentStatus: 'api/Trainer/ChangeAssignmentStatus/',

        startTrial: 'api/User/StartModule/',

        //Admin
        addBatch: 'api/Admin/AddBatch',
        getProgramList: 'api/Trainer/GetProgramList',

    };

    constructor(private user: UserService, private common: CommonService,
        private webapi: WebHttpService,
        private nativeapi: NativeHttpService, private http: HttpClient, private router: Router, private title: Title, private meta: Meta) {
        this.domain = environment.domain;
    }

    getDataFromUrl(url: string) {

        return new Promise((res, rej) => {
            var params = "";
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(url)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(url).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    public postDataToUrl(url: string, data: any) {
        return new Promise((res, rej) => {

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(url, data)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(url, data).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    manageSubscriptions() {

        return new Promise((res, rej) => {
            var params = "";
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.manageSubscriptions)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.manageSubscriptions).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    getAssessment(alias: any, assessmentId?: number, submissionId?: number) {

        return new Promise((res, rej) => {
            var params = "";

            if(assessmentId && submissionId) {
                params = `?assessmentId=${assessmentId}&submissionId=${submissionId}`;
            }
            console.log(this.domain + this.apis.getAssessment + alias + params);
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getAssessment + alias + params)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getAssessment + alias + params).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    postAssessmentSubmission(submissions: any, assessmentId: any, submissionId?: number) {

        return new Promise((res, rej) => {
            var params = "";
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.submitAssessment + "?id=" + assessmentId + "&submissionId=" + submissionId, submissions)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.submitAssessment + "?id=" + assessmentId + "&submissionId=" + submissionId, submissions).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    saveAssessmentSubmission(submissions: any, assessmentId: any, submissionId?: number, currentQuestion?: number) {

        return new Promise((res, rej) => {
            var params = "";
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.saveAssessmentSubmission + "?id=" + assessmentId + "&submissionId=" + submissionId + "&currentQuestion=" + currentQuestion, submissions)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.saveAssessmentSubmission + "?id=" + assessmentId + "&submissionId=" + submissionId + "&currentQuestion=" + currentQuestion, submissions).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    createAndroidInAppOrder(payload: any) {

        return new Promise((res, rej) => {
            var params = "";
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.createCoreOrderAndroid + params, payload)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.createCoreOrderAndroid + params, { payload: payload }).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    coreOrderAndroidApproved(order_id: any, payload: any) {

        return new Promise((res, rej) => {
            var params = "?order_id=" + order_id;
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.coreOrderAndroidApproved + params, payload)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.coreOrderAndroidApproved + params, payload).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    coreOrderAndroidAcknowledged(order_id: any, payload: any) {

        return new Promise((res, rej) => {
            var params = "?order_id=" + order_id;
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.coreOrderAndroidAcknowledged + params, payload)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.coreOrderAndroidAcknowledged + params, payload).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    getDashboard() {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getDashboard, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getAcapSubscriptions() {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getAcapSubscriptions, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getOnboardingData() {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getOnboardingdata, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getSignupData() {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getSignupData, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    submitCategoryForm(data: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .post(this.domain + this.apis.submitCategoryForm, data, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    joinVenusPartnerDiscoveryWaitlist(data: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .post(this.domain + this.apis.joinVenusPartnerDiscoveryWaitlist, data, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getProgramSchedule(id: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getPrograms, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getBatchSchedule(id: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getBatchSchedule + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getBatchReport(id: number, programParticipantId: number, istrainer?: boolean) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";
            if (programParticipantId) {
                params = "?pp=" + programParticipantId;
            }
            if (istrainer) {
                params += "&t=" + istrainer;
            }
            this.http
                .get(this.domain + this.apis.getBatchReport + id + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getSituation(id: any) {
        return new Promise((res, rej) => {

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getSituation + id)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getSituation + id).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });


            // let token: string = this.user.token;
            // var httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };
            // this.http
            //     .get(this.domain + this.apis.getSituation + id, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    viewSituation(id: any) {
        return new Promise((res, rej) => {
            var category = "?category=" + environment.category;
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.viewSituation + id + category)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.viewSituation + id + category).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
            // let token: string = this.user.token;
            // var httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };
            // this.http
            //     .get(this.domain + this.apis.viewSituation + id, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    getSituationAnalysis(id: any) {
        return new Promise((res, rej) => {

            this.common.canUseNative().then((isNative: boolean) => {

                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getSituationAnalysis + id)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getSituationAnalysis + id).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

            // let token: string = this.user.token;
            // var httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };
            // this.http
            //     .get(this.domain + this.apis.getSituationAnalysis + id, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    deleteSituation(id: number) {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.deleteDataFromUrl(this.domain + this.apis.deleteSituation + id)
                        .then((r: any) => {
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.deleteDataFromUrl(this.domain + this.apis.deleteSituation + id).then((r: any) => {
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });
                }
            });
        });
    }

    getSituationDetails(id: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getSituationDetail + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getAdiosession(id: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getAdiosession + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getContemplationItems() {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getContemplationItems, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getUserJournal(last_note?: any, content_id?: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = [];
            if (last_note != null) {
                params.push("lm=" + last_note);
            }
            if (content_id != null) {
                params.push("cId=" + content_id);
            }

            var p = "";
            if (params.length > 0) {
                p = "?" + params.join('&');
            }
            this.http
                .get(this.domain + this.apis.getNotes + p, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    addToJournal(note: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .post(this.domain + this.apis.addToJournal, note, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    startAdiosession(session: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .post(this.domain + this.apis.startAdiosession, session, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    startContemplation(id: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.startContemplation + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    stopContemplation(id: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.stopContemplation + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    addUserTag(tag: string) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .post(this.domain + this.apis.addUserTag, {
                    Label: tag
                }, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getAdioscopeMeta() {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                var category = "?category=" + environment.category;
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getAdioscopeMeta + category)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getAdioscopeMeta + category).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

            // let token: string = this.user.token;
            // var httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            //     observer: 'response'
            // };
            // this.http
            //     .get(this.domain + this.apis.getAdioscopeMeta, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r, response: r });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }


    getPublicAdioscope(param: any) {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                var category = "&category=" + environment.category;
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getPublicAdioscope + "?pageNumber=" + param.currentPage + "&pageSize=" + param.pageSize + category)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getPublicAdioscope + "?pageNumber=" + param.currentPage + "&pageSize=" + param.pageSize + category).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

            // let token: string = this.user.token;
            // var httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            //     observer: 'response'
            // };
            // this.http
            //     .get(this.domain + this.apis.getPublicAdioscope + "?pageNumber=" + param.currentPage + "&pageSize=" + param.pageSize, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r, response: r });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    getPersonalAdioscope(param: any) {
        return new Promise((res, rej) => {
            var category = "&category=" + environment.category;
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getPersonalAdioscope + "?pageNumber=" + param.currentPage + "&pageSize=" + param.pageSize + category)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getPersonalAdioscope + "?pageNumber=" + param.currentPage + "&pageSize=" + param.pageSize + category).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

            // let token: string = this.user.token;
            // var httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };
            // this.http
            //     .get(this.domain + this.apis.getPersonalAdioscope + "?pageNumber=" + param.currentPage + "&pageSize=" + param.pageSize, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    getClearedAdioscope(param: any) {
        return new Promise((res, rej) => {
            var category = "&category=" + environment.category;
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getClearedAdioscope + "?pageNumber=" + param.currentPage + "&pageSize=" + param.pageSize + category)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getClearedAdioscope + "?pageNumber=" + param.currentPage + "&pageSize=" + param.pageSize + category).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });
    }

    getPrograms() {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getPrograms, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getUserDetails() {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getUserDetails, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getPublicPrograms() {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getPublicPrograms, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getProgram(id: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getProgram + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getProgramDetails(alias: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getProgramDetails + alias, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getPublicProgram(id: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getPublicProgram + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getCenterDetails(centerRef: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getCenter + '?c=' + centerRef, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getCenterProgram(centerProgramId: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getCenterProgram + centerProgramId, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getContentNotes() {
        return new Promise((res, rej) => {

            var params = "";

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getContentNotes + params)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getContentNotes + params).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });
    }

    logExercise(exercise: any) {
        return new Promise((res, rej) => {

            var params = "";

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.logExercise + params, exercise)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.logExercise + params, exercise).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });
    }

    getExerciseSummaryForLast7Days() {
        return new Promise((res, rej) => {

            var params = "";

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getExerciseSummaryForLast7Days + params)
                        .then((r: any) => {
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getExerciseSummaryForLast7Days + params).then((r: any) => {
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });
                }
            });
        });
    }

    getExerciseLogs(param: any) {
        return new Promise((res, rej) => {

            var params = "?pageNumber=" + param.currentPage + "&pageSize=" + param.pageSize ;

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getExerciseLogs + params)
                        .then((r: any) => {
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getExerciseLogs + params).then((r: any) => {
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });
                }
            });
        });
    }

    getFavorites() {
        return new Promise((res, rej) => {

            var params = "";

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getFavorites + params)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getFavorites + params).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });
    }

    addFavorite(id: number, type: string) {
        return new Promise((res, rej) => {

            var params = "";
            var param: any = [];
            if (type == 'content') {
                param.push('cId=' + id);
            }
            if (type == 'guided-contemplation') {
                param.push('gcId=' + id);
            }

            if (param.length > 0) {
                params = "?" + param.join('&');
            }
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.addFavorite + params)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.addFavorite + params).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });
    }

    removeFavorite(id: number, type: string) {
        return new Promise((res, rej) => {

            var params = "";
            var param: any = [];
            if (type == 'content') {
                param.push('cId=' + id);
            }
            if (type == 'guided-contemplation') {
                param.push('gcId=' + id);
            }

            if (param.length > 0) {
                params = "?" + param.join('&');
            }

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.removeFavorite + params)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.removeFavorite + params).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });
    }

    requestAccountDeletion(feedback: any) {
        return new Promise((res, rej) => {

            var params = "";

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.requestAccountDeletion + params, feedback)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.requestAccountDeletion + params, feedback).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });
    }

    getUserSubscription(code?: string) {
        return new Promise((res, rej) => {

            var params = "";

            if (code) {
                params = "?code=" + code
            }

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getUserSubscription + params)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getUserSubscription + params).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    useAffiliationCode(code: any, trial?: boolean, mmk?: any, sp?: any) {
        return new Promise((res, rej) => {

            var params = "";
            var param: any = [];
            if (mmk) {
                param.push("mmk=" + mmk);
            }
            if (sp) {
                param.push("sp=" + sp);
            }

            if (trial) {
                param.push("trial=" + trial);
            }
            if (param.length > 0) {
                params = "?" + param.join("&");
            }

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.useAffiliationCode + code + params)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.useAffiliationCode + code + params).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

            // let token: string = this.user.token;
            // var httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };

            // this.http
            //     .get(this.domain + this.apis.enrollBatch + code + params, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r });
            //     })
            //     .catch(e => {
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg: msg });
            //     });
        });
    }

    enrollBatch(code: any, trial?: boolean, mmk?: any, sp?: any) {
        return new Promise((res, rej) => {

            var params = "";
            var param: any = [];
            if (mmk) {
                param.push("mmk=" + mmk);
            }
            if (sp) {
                param.push("sp=" + sp);
            }

            if (trial) {
                param.push("trial=" + trial);
            }
            if (param.length > 0) {
                params = "?" + param.join("&");
            }

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.enrollBatch + code + params)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.enrollBatch + code + params).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    upgradeCoreTrial(orderId: any) {
        return new Promise((res, rej) => {

            var params = "";
            var param: any = [];
            if (param.length > 0) {
                params = "?" + param.join("&");
            }

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.upgradeCoreTrial + "?order_ref=" + orderId + params)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.upgradeCoreTrial + "?order_ref=" + orderId + params).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

            // let token: string = this.user.token;
            // var httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };

            // this.http
            //     .get(this.domain + this.apis.enrollBatch + code + params, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r });
            //     })
            //     .catch(e => {
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg: msg });
            //     });
        });
    }

    upgradeTrial(programParticipantId: any) {
        return new Promise((res, rej) => {

            var params = "";
            var param: any = [];
            if (param.length > 0) {
                params = "?" + param.join("&");
            }

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.upgradeTrial + programParticipantId + params)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.upgradeTrial + programParticipantId + params).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

            // let token: string = this.user.token;
            // var httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };

            // this.http
            //     .get(this.domain + this.apis.enrollBatch + code + params, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r });
            //     })
            //     .catch(e => {
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg: msg });
            //     });
        });
    }

    joinBatch(code: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.joinBatch + '?c=' + code, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    joinProgram(id: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.joinProgram + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getApplications() {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getApplicationData, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getPaymentDetails(id: any) {
        return new Promise((res, rej) => {
            var token: string = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token,
                }),
            };

            this.http
                .get(this.domain + this.apis.getPaymentDetails + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    var msg = e.error;
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getBatches(id: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.GetBatches + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    uploadContentVideoFile(blob: Blob, filename: string) {

        return new Promise((res, rej) => {


            let formData = new FormData();
            formData.append('video', blob, filename);


            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postFormDataToUrl(this.domain + this.apis.uploadContentVideoFile, formData)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postFormDataToUrl(this.domain +
                        this.apis.uploadContentVideoFile, formData).then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });

                }
            });
        });
    }

    uploadContentFile(id: number, data: any) {
        return new Promise((res, rej) => {
            let formData = new FormData();

            formData.append('file', data);

            let token = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    Token: token,
                }),
            };

            this.http
                .post(
                    this.domain +
                    this.apis.uploadContentFile +
                    '?c=' + id,
                    formData,
                    httpOptions
                )
                .toPromise()
                .then((r: any) => {
                    ////console.log(r);
                    res({ success: true, data: r });
                })
                .catch(e => {
                    // ////console.log("Error in get", e);
                    if (!e.ok && (e.status < 200 || e.status >= 300)) {
                        if (e.status == 422) {
                            rej({ success: false, data: e, msg: 'helper not found' });
                        } else if (e.status == 401) {
                            this.logout();
                            this.router.navigateByUrl('');
                            rej({ success: false, data: e, msg: 'Session Expired' });
                            // this.logout();
                        } else if (e.status == 404) {
                            rej({
                                success: false,
                                data: e,
                                msg: 'Unable to complete your request',
                            });
                        } else if (e.status == 403) {
                            rej({ success: false, data: e, msg: 'Unauthorized access' });
                        } else if (e.status == 406) {
                            rej({
                                success: false,
                                data: e,
                                msg: 'Unable to update the record as its been linked somewhere',
                            });
                        } else {
                            rej({ success: false, data: e });
                        }
                    }
                });
        });
    }

    contentCompleted(id: number) {

        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.contentCompleted + id)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.contentCompleted + id).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

            // let token = this.user.token;
            // var httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };

            // this.http
            //     .get(this.domain + this.apis.contentCompleted + id, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({
            //             success: true,
            //             data: r,
            //         });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    deleteContentFile(id: number) {
        return new Promise((res, rej) => {
            let token = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .get(this.domain + this.apis.deleteContentFile + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    addValueToAssignmentTopic(assignmentValue: any) {

        return new Promise((res, rej) => {
            let token = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .post(this.domain + this.apis.addValueToAssignment, assignmentValue, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    addValueToDateAssignmentTopic(assignmentValue: any) {

        return new Promise((res, rej) => {
            let token = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .post(this.domain + this.apis.addValueToDateAssignment, assignmentValue, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    scheduleSession(sessions: any) {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.scheduleContemplationSession, sessions)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.scheduleContemplationSession, sessions).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    addSituation(situation: any) {
        return new Promise((res, rej) => {
            var params = "";
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.addSituation + params, situation)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.addSituation + params, situation).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    addSituationV2(situation: any) {
        return new Promise((res, rej) => {
            var params = "?category=" + environment.category;
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.addSituationV2 + params, situation)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.addSituationV2 + params, situation).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });
    }

    addBulkSituationV2(situation: any) {
        return new Promise((res, rej) => {
            var params = "?category=" + environment.category;
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.addBulkSituations + params, situation)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.addBulkSituations + params, situation).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });
    }

    updateSituationDescription(situation: any) {
        return new Promise((res, rej) => {

            var params = "";
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.updateSituationDescription + params, situation)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.updateSituationDescription + params, situation).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });
    }

    updateSituationClarity(situation: any) {
        return new Promise((res, rej) => {
            var params = "";
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.updateSituationClarity + params, situation)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.updateSituationClarity + params, situation).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

            // let token = this.user.token;
            // let httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };
            // // var params = "?p=" + programId + "&b=" + batchId + "&pp=" + programParticipantId;
            // var params = "";
            // this.http
            //     .post(this.domain + this.apis.updateSituationClarity + params, situation, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({
            //             success: true,
            //             data: r,
            //         });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    updateSituationPrincipleTrigger(situationPrincipleTrigger: any) {
        return new Promise((res, rej) => {
            var params = "";
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.updateSituationPrincipleTrigger + params, situationPrincipleTrigger)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.updateSituationPrincipleTrigger + params, situationPrincipleTrigger).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

            // let token = this.user.token;
            // let httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };
            // // var params = "?p=" + programId + "&b=" + batchId + "&pp=" + programParticipantId;
            // var params = "";
            // this.http
            //     .post(this.domain + this.apis.updateSituationPrincipleTrigger + params, situationPrincipleTrigger, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({
            //             success: true,
            //             data: r,
            //         });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    updateSituationPrincipleMap(situationPrincipleMap: any) {
        return new Promise((res, rej) => {
            var params = "";
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.updateSituationPrincipleMap + params, situationPrincipleMap)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.updateSituationPrincipleMap + params, situationPrincipleMap).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

            // let token = this.user.token;
            // let httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };
            // // var params = "?p=" + programId + "&b=" + batchId + "&pp=" + programParticipantId;
            // var params = "";
            // this.http
            //     .post(this.domain + this.apis.updateSituationPrincipleMap + params, situationPrincipleMap, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({
            //             success: true,
            //             data: r,
            //         });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    addSituationInProgram(situation: any, programId: number, batchId: number, programParticipantId: number) {
        return new Promise((res, rej) => {
            let token = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "?p=" + programId + "&b=" + batchId + "&pp=" + programParticipantId;
            this.http
                .post(this.domain + this.apis.addSituationInProgram + params, situation, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    addAnalysisInSituation(analysis: any, situationId: number, programParticipantId: number) {
        var params = "?s=" + situationId + "&pp=" + programParticipantId;
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.addAnalysisInSituation + params, analysis)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.addAnalysisInSituation + params, analysis).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

            // let token = this.user.token;
            // let httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };
            // var params = "?s=" + situationId + "&pp=" + programParticipantId;
            // this.http
            //     .post(this.domain + this.apis.addAnalysisInSituation + params, analysis, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({
            //             success: true,
            //             data: r,
            //         });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    getParticipantTopicValues(topicId: any, programParticipantId: number) {

        return new Promise((res, rej) => {
            let token = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .get(this.domain + this.apis.getParticipantAssignmentTopics + topicId + "?p=" + programParticipantId, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    resendVerificationEmail() {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .get(this.domain + this.apis.ResendVerification, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    var msg = e.error;
                    rej({ success: false, data: e, msg });
                });
        });
    }

    verifyEmail(verifyToken: string) {
        return new Promise((res, rej) => {
            var token: string = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token,
                }),
            };

            this.http
                .get(this.domain + this.apis.VerifyEmail + '?v=' + verifyToken, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    var msg = e.error;
                    rej({ success: false, data: e, msg });
                });
        });
    }

    verifyResetPassword(verifyToken: string) {
        return new Promise((res, rej) => {
            var token: string = this.user.token;
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.VerifyResetPassword + '?v=' + verifyToken)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.VerifyResetPassword + '?v=' + verifyToken).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
            // let httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token,
            //     }),
            // };

            // this.http
            //     .get(this.domain + this.apis.VerifyResetPassword + '?v=' + verifyToken, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         var msg = e.error;
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    checkToken(token: string) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
                withCredentials: true,
            };

            this.http
                .get(this.domain + this.apis.validateToken, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    serviceLogin(user_ref: string, service: string, s_token: string) {
        return new Promise((res, rej) => {

            this.common.canUseNative().then((isNative: boolean) => {
                var url = this.domain + this.apis.serviceLogin + "?category=" + environment.category + "&ref=" + user_ref + "&service=" + service + "&stoken=" + s_token;
                if (isNative) {
                    this.nativeapi.getDataFromUrl(url)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(url).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });
    }

    validateToken(token: string) {
        return new Promise((res, rej) => {

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.validateToken)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.validateToken).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

            // let token: string = this.user.token;
            // let httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            //     withCredentials: true,
            // };

            // this.http
            //     .get(this.domain + this.apis.validateToken, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    register(user: any, params?: string) {
        return new Promise((res, rej) => {

            if (params && params != "") {
                params = params + "&category=" + environment.category;
            } else {
                params = "?category=" + environment.category;
            }
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.Register + params, user).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.Register + params, user).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });
                }
            });



            // let httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //     }),
            // };
            // this.http
            //     .post(this.domain + this.apis.Register + params, user, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         this.user.setToken(r.Token);
            //         res({
            //             success: true,
            //             data: r,
            //         });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    login(user: any) {
        return new Promise((res, rej) => {
            var params = "?category=" + environment.category;
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.Login + params, user).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.Login + params, user).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                    // let httpOptions = {
                    //     headers: new HttpHeaders({
                    //         'Content-Type': 'application/json',
                    //     }),
                    //     withCredentials: true,
                    // };
                    // this.http
                    //     .post(this.domain + this.apis.Login, user, httpOptions)
                    //     .toPromise()
                    //     .then((r: any) => {
                    //         this.user.setToken(r.Auth.Token);
                    //         res({
                    //             success: true,
                    //             data: r,
                    //         });
                    //     })
                    //     .catch(e => {
                    //         ////console.log('Error in get', e);
                    //         let msg = undefined;
                    //         if (typeof e.error === 'string') {
                    //             msg = e.error;
                    //         }
                    //         rej({ success: false, data: e, msg });
                    //     });
                }
            });
        });
    }

    externallogin(user: any) {
        return new Promise((res, rej) => {
            var params = "?category=" + environment.category;
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.externalLogin + params, user).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.externalLogin + params, user).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                    // let httpOptions = {
                    //     headers: new HttpHeaders({
                    //         'Content-Type': 'application/json',
                    //     }),
                    //     withCredentials: true,
                    // };
                    // this.http
                    //     .post(this.domain + this.apis.externalLogin, user, httpOptions)
                    //     .toPromise()
                    //     .then((r: any) => {
                    //         this.user.setToken(r.Auth.Token);
                    //         res({
                    //             success: true,
                    //             data: r,
                    //         });
                    //     })
                    //     .catch(e => {
                    //         ////console.log('Error in get', e);
                    //         let msg = undefined;
                    //         if (typeof e.error === 'string') {
                    //             msg = e.error;
                    //         }
                    //         rej({ success: false, data: e, msg });
                    //     });
                }
            });
        });
    }


    externalAppleLogin(user: any) {
        return new Promise((res, rej) => {
            var params = "?category=" + environment.category;
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.externalLoginApple + params, user).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.externalLoginApple + params, user).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                    // let httpOptions = {
                    //     headers: new HttpHeaders({
                    //         'Content-Type': 'application/json',
                    //     }),
                    //     withCredentials: true,
                    // };
                    // this.http
                    //     .post(this.domain + this.apis.externalLogin, user, httpOptions)
                    //     .toPromise()
                    //     .then((r: any) => {
                    //         this.user.setToken(r.Auth.Token);
                    //         res({
                    //             success: true,
                    //             data: r,
                    //         });
                    //     })
                    //     .catch(e => {
                    //         ////console.log('Error in get', e);
                    //         let msg = undefined;
                    //         if (typeof e.error === 'string') {
                    //             msg = e.error;
                    //         }
                    //         rej({ success: false, data: e, msg });
                    //     });
                }
            });
        });
    }

    subscribeAcap(id: any) {
        return new Promise((res, rej) => {
            var token: string = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .get(this.domain + this.apis.subscribeToAcap + '/' + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    var msg = e.error;
                    rej({ success: false, data: e, msg });
                });
        });
    }

    validateAcapSubscription(session_id: string) {
        return new Promise((res, rej) => {
            var token: string = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token,
                }),
            };

            this.http
                .get(this.domain + this.apis.validateAcapSubscription + '?session_id=' + session_id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    var msg = e.error;
                    rej({ success: false, data: e, msg });
                });
        });
    }

    createSubscription(order: any, redirect: string) {
        return new Promise((res, rej) => {
            var token: string = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            var params = "?redirect=" + redirect;
            this.http
                .post(this.domain + this.apis.Subscribe + params, order, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    var msg = e.error;
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getOrderDetails(session_id: string) {
        return new Promise((res, rej) => {

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getCoreOrderDetails + '?order_ref=' + session_id)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getCoreOrderDetails + '?order_ref=' + session_id).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

            // var token: string = this.user.token;
            // let httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token,
            //     }),
            // };

            // this.http
            //     .get(this.domain + this.apis.getOrderDetails + '?order_ref=' + session_id, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         var msg = e.error;
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }


    processJoiningBatch(data: any, code: string, trial?: boolean) {
        return new Promise((res, rej) => {

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.processJoiningBatch + '?code=' + code + "&trial=" + trial, data)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.processJoiningBatch + '?code=' + code + "&trial=" + trial, data).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

            // var token: string = this.user.token;
            // let httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token,
            //     }),
            // };

            // this.http
            //     .get(this.domain + this.apis.getOrderDetails + '?order_ref=' + session_id, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         var msg = e.error;
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }


    processUpgradeTrial(data: any, ppId: any) {
        return new Promise((res, rej) => {

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.processUpgradeTrial + '?pp=' + ppId, data)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.processJoiningBatch + '?pp=' + ppId, data).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    validateSubscription(order_id: string, session_id: string) {
        return new Promise((res, rej) => {
            var token: string = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token,
                }),
            };

            this.http
                .get(this.domain + this.apis.validateSubscription + '?session_id=' + session_id + "&order_id=" + order_id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    var msg = e.error;
                    rej({ success: false, data: e, msg });
                });
        });
    }


    validatePlan(session_id: string) {
        return new Promise((res, rej) => {
            var token: string = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token,
                }),
            };

            this.http
                .get(this.domain + this.apis.validatePlan + '?session_id=' + session_id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    var msg = e.error;
                    rej({ success: false, data: e, msg });
                });
        });
    }

    validateOrder(order_id: string, session_id: string) {
        return new Promise((res, rej) => {
            var token: string = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token,
                }),
            };

            this.http
                .get(this.domain + this.apis.validateOrder + '?session_id=' + session_id + "&order_id=" + order_id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    var msg = e.error;
                    rej({ success: false, data: e, msg });
                });
        });
    }

    unsubscribeUser(email: any) {
        return new Promise((res, rej) => {
            var params = "?category=" + environment.category;
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.unsubscribe + email).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.unsubscribe + email).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    resetPassword(user: any) {
        return new Promise((res, rej) => {


            var params = "?category=" + environment.category;
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.ResetPassword + params, user).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.ResetPassword + params, user).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });


            // let httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //     }),
            // };
            // this.http
            //     .post(this.domain + this.apis.ResetPassword, user, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({
            //             success: true,
            //             data: r,
            //         });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }


    setupUser(user: any) {
        return new Promise((res, rej) => {
            let token = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            var param = "";
            if (user.Code) {
                param = "?c=" + user.Code;
            }

            this.http
                .post(this.domain + this.apis.SetupUser + param, user, httpOptions)
                .toPromise()
                .then((r: any) => {
                    ////console.log(r);
                    this.user.setToken(r.Auth.Token);
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    submitApplication(application: any) {
        return new Promise((res, rej) => {
            let token = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .post(this.domain + this.apis.SubmitApplication, application, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    logout() {
        localStorage.clear();
        this.user.isloggedin = false;
        this.user.user = undefined;
        this.user.setToken(null);
        this.user.logout();
    }





    //Trainer's Endpoints



    getTrainerParticipants(param: any) {
        var category = "&category=" + environment.category;
        var url = this.domain + this.apis.getTrainerParticipants + "?pageNumber=" + param.currentPage + "&pageSize=" + param.pageSize + category + "&search=" + param.search;
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(url, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getTrainerPrograms() {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getTrainerPrograms, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getTrainerAssessments(participantId?: number) {
        return new Promise((res, rej) => {
            var params = "";
            if (participantId) {
                params = "?participantId=" + participantId;
            }
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getTrainerAssessments + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getTrainerExerciseSummaryForLast7Days(userIds?: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .post(this.domain + this.apis.getTrainerExerciseSummaryForLast7Days, { userIds: userIds }, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    createTrainerAssessmentSubmission(assessmentId: number, participantIds: number[]) {
        return new Promise((res, rej) => {

            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .post(this.domain + this.apis.createTrainerAssessmentSubmission, { AssessmentId: assessmentId, ParticipantIds: participantIds }, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getTrainerParticipantsList() {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getTrainerParticipantsList, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getTrainerGradingAssignments() {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getTrainerModules, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getTrainerModules() {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getTrainerModules, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getTrainerExercises() {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getTrainerExercises, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getTrainerBatchParticipant(batchId: any, programParticipantId: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getTrainerBatchParticipant + programParticipantId + "?batchId=" + batchId, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getTrainerBatchParticipants(batchId: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getTrainerBatchParticipants + batchId, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getTrainerEndBatchWeek(batchId: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getTrainerEndBatchWeek + batchId, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    createNewAssignmentTopic(assignmentTopic: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .post(this.domain + this.apis.createNewAssignmentTopic, assignmentTopic, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    addAssignmentsToBatch(assignments: any, batchId: number, programId: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "?b=" + batchId + "&p=" + programId;
            this.http
                .post(this.domain + this.apis.addAssignmentsToBatch + params, assignments, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getTrainerChangeAssignmentStatus(participantAssignmentId: number, batchId: number, value: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "?b=" + batchId + "&p=" + value;
            this.http
                .get(this.domain + this.apis.getTrainerChangeAssignmentStatus + participantAssignmentId + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getOverDueAssignments() {
        return new Promise((res, rej) => {
            var category = "?category=" + environment.category;
            var url = this.domain + this.apis.getOverDueAssignments + category;
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(url)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(url).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    markAssignmentAsCompletedByParticipant(participantAssignmentId: any, remarks: string) {
        return new Promise((res, rej) => {
            var category = "?category=" + environment.category;
            var url = this.domain + this.apis.markAssignmentAsCompleted + category;
            var data = {
                AssignmentId: participantAssignmentId,
                Remarks: remarks
            };
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(url, data)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(url, data).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    getPendingAssignments(param) {
        return new Promise((res, rej) => {
            var category = "&category=" + environment.category;
            var url = this.domain + this.apis.getPendingAssignments + "?pageNumber=" + param.currentPage + "&pageSize=" + param.pageSize + category;
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(url)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(url).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    getCompletedAssignments(param) {
        return new Promise((res, rej) => {
            var category = "&category=" + environment.category;
            var url = this.domain + this.apis.getCompletedAssignments + "?pageNumber=" + param.currentPage + "&pageSize=" + param.pageSize + category
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(url)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(url).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    getAssignmentContent(assignmentId: number) {
        return new Promise((res, rej) => {
            var category = "?category=" + environment.category;
            var url = this.domain + this.apis.getAssignmentContent + assignmentId + category;
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(url)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(url).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    getEligibleAssessments(param) {
        return new Promise((res, rej) => {
            var category = "&category=" + environment.category;
            var url = this.domain + this.apis.getEligibleAssessments + "?pageNumber=" + param.currentPage + "&pageSize=" + param.pageSize + category
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(url)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(url).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    getChangeAssignmentStatus(participantAssignmentId: number, batchId: number, value: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "?b=" + batchId + "&p=" + value;
            this.http
                .get(this.domain + this.apis.getChangeAssignmentStatus + participantAssignmentId + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    addTrainerNewAssignmentsToParticipants(assignments: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .post(this.domain + this.apis.addTrainerNewAssignmentsToParticipants, assignments, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    markAssignmentAsCompletedByTrainer(participantAssignmentId: number, remarks: string) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .post(this.domain + this.apis.trainerMarkAssignmentAsCompleted, {
                    AssignmentId: participantAssignmentId,
                    Remarks: remarks
                }, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    publishAssignmentsToBatch(assignments: any, batchId: number, programId: number, programParticipantId?: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "?b=" + batchId + "&p=" + programId;
            if (programParticipantId) {
                params += "&pp=" + programParticipantId;
            }
            this.http
                .post(this.domain + this.apis.publishAssignmentsToBatch + params, assignments, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    changeSituationStatus(id: any, state: any) {
        return new Promise((res, rej) => {
            var params = "?s=" + state;

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.changeSituationStateV2 + id + params)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.changeSituationStateV2 + id + params).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

            // let token: string = this.user.token;
            // var httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };
            // var params = "?s=" + state;

            // this.http
            //     .get(this.domain + this.apis.changeSituationState + id + params, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    setSituationImportance(id: any, importance: any) {
        return new Promise((res, rej) => {
            var params = "?i=" + importance;

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.setSituationImportanceV2 + id + params)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.setSituationImportanceV2 + id + params).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    sendSituationLove(id: any, message: any, emotion: any, importance: any) {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {

                var data: any = {
                    SituationId: id,
                    Message: message,
                    Emotion: emotion,
                    Importance: importance,
                };

                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.sendSituationLoveV2, data)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.sendSituationLoveV2, data).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    changeSituationPrivacy(id: any, privacy: any) {
        return new Promise((res, rej) => {
            var params = "?p=" + privacy;
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.changeSituationPrivacy + id + params)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.changeSituationPrivacy + id + params).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

            // let token: string = this.user.token;
            // var httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };
            // var params = "?p=" + privacy;

            // this.http
            //     .get(this.domain + this.apis.changeSituationPrivacy + id + params, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    //User Apis
    setNewPassword(data: any, token: any) {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.NewPassword, data)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.NewPassword, data).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
            // let httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };

            // this.http
            //     .post(this.domain + this.apis.NewPassword, data, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({
            //             success: true,
            //             data: r,
            //         });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    changePassword(data: any) {
        return new Promise((res, rej) => {
            let token = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .post(this.domain + this.apis.ChangePassword, data, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    recommendUsers(data: any, program?: any) {
        return new Promise((res, rej) => {
            let token = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            var params = "";
            if (program) {
                params = "?p=" + program;
            }

            this.http
                .post(this.domain + this.apis.RecommendUsers + params, data, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    createOrganisation(data: any) {
        return new Promise((res, rej) => {
            let token = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .post(this.domain + this.apis.createOrganisation, data, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    saveBasicProfile(data: any) {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.saveBasicProfile, data).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.saveBasicProfile, data).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });
                }
            });
            // let token = this.user.token;
            // let httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };

            // this.http
            //     .post(this.domain + this.apis.saveBasicProfile, data, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({
            //             success: true,
            //             data: r,
            //         });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    getOrganisationUsers(id: any) {
        return new Promise((res, rej) => {
            let token = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .get(this.domain + this.apis.getOrganisationUsers + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    inviteUsers(data: any, organisationId: any) {
        return new Promise((res, rej) => {
            let token = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .post(this.domain + this.apis.inviteUserInOrganisation + "?org_id=" + organisationId, data, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = e.error.Message;
                    rej({ success: false, data: e, msg });
                });
        });
    }

    removeInviteFromOrganisation(email: any, organisationId: any) {
        return new Promise((res, rej) => {
            let token = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .get(this.domain + this.apis.removeInviteFromOrganisation + "?org_id=" + organisationId + "&email=" + email, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = e.error.Message;
                    rej({ success: false, data: e, msg });
                });
        });
    }

    setFcmToken(token: string) {
        return new Promise((res, rej) => {
            return new Promise((res, rej) => {
                this.common.canUseNative().then((isNative: boolean) => {
                    if (isNative) {
                        this.nativeapi.getDataFromUrl(this.domain + this.apis.setFcmToken + "?token=" + token + "&app=" + environment.appdomain + "&category=" + environment.category).then((r: any) => {
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                    } else {
                        this.webapi.getDataFromUrl(this.domain + this.apis.setFcmToken + "?token=" + token + "&app=" + environment.appdomain + "&category=" + environment.category).then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                    }
                });
            });
        });
    }

    setAuthCode(token: string) {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.setAuthCode + "?token=" + token).then((r: any) => {
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.setAuthCode + "?token=" + token).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });
                }
            });
        });
    }

    getSubscriptions() {
        return new Promise((res, rej) => {
            let token = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .get(this.domain + this.apis.getSubscriptions, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = e.error.Message;
                    rej({ success: false, data: e, msg });
                });
        });
    }

    cancelSubscription(orderId: any) {
        return new Promise((res, rej) => {
            let token = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            this.http
                .get(this.domain + this.apis.cancelSubscription + orderId, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = e.error.Message;
                    rej({ success: false, data: e, msg });
                });
        });
    }



    //Vision Planner
    getPlanner() {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";
            this.http
                .get(this.domain + this.apis.getVisionPlanner + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getDimensions() {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";
            this.http
                .get(this.domain + this.apis.getDimensions, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    addCharacter(dimensionId: number, character: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";
            this.http
                .post(this.domain + this.apis.addCharacter + dimensionId, character, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    addAspiration(characterId: number, aspiration: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";
            this.http
                .post(this.domain + this.apis.addAspiration + characterId, aspiration, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    addAspirationResult(aspirationId: number, result: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";
            this.http
                .post(this.domain + this.apis.addAspirationResult + aspirationId, result, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }
    
    addAspirationResultTask(resultId: number, task: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";
            this.http
                .post(this.domain + this.apis.addAspirationResultTask + resultId, task, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    saveCharacterVision(characterId: number, vision: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";
            this.http
                .post(this.domain + this.apis.saveCharacterVision + characterId, vision, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getVersionDetails(plannerId: number, versionNo: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "?v=" + versionNo;
            this.http
                .get(this.domain + this.apis.getVersionDetails + plannerId + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    setupPlanner(planner: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";
            this.http
                .post(this.domain + this.apis.setupPlanner + params, planner, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    savePlanner(planner: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";
            this.http
                .post(this.domain + this.apis.savePlanner + params, planner, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    previewPlanner(planner: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";
            this.http
                .post(this.domain + this.apis.previewPlanner + params, planner, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    saveDimensions(dimensions: any, plannerId?: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";
            if (plannerId) {
                params = "?pId=" + plannerId
            }
            this.http
                .post(this.domain + this.apis.saveDimensions + params, {
                    PlannerId: plannerId,
                    Dimensions: dimensions
                }, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    saveDimension(dimension: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";

            this.http
                .post(this.domain + this.apis.saveDimension + params, dimension, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    saveArea(area: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";

            this.http
                .post(this.domain + this.apis.saveArea + params, area, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    saveTask(task: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";

            this.http
                .post(this.domain + this.apis.saveTask + params, task, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    removeTask(id: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";

            this.http
                .delete(this.domain + this.apis.removeTask + id + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    removeArea(id: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";

            this.http
                .delete(this.domain + this.apis.removeArea + id + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    removeDimension(id: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            var params = "";

            this.http
                .delete(this.domain + this.apis.removeDimension + id + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }




    //Venus Match 
    getVenusDetails() {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getVenusDetails, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }



    //Forum (Ask Us) 
    getForumQuestions() {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getForumQuestions, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getForumQuestion(id: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getForumQuestion + id, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    saveForumQuestion(question: any) {
        return new Promise((res, rej) => {
            let token = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            // var params = "?p=" + programId + "&b=" + batchId + "&pp=" + programParticipantId;
            var params = "";
            this.http
                .post(this.domain + this.apis.saveForumQuestion + params, question, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }


    //Program Workspace
    getProgramSpace(batchId: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getProgramSpace + batchId, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getCurriculum(batchId: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getProgramContent + batchId, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getProfileQuestions(contentId: any, programParticipantId: any) {
        return new Promise((res, rej) => {
            var params = "?pp=" + programParticipantId;
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getProfileQuestions + contentId + params)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getProfileQuestions + contentId + params).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

            // let token: string = this.user.token;
            // var httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };
            // var params = "?pp=" + programParticipantId;
            // this.http
            //     .get(this.domain + this.apis.getProfileQuestions + contentId + params, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    saveEventLog(eventLog: any) {
        return new Promise((res, rej) => {
            var params = "";
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.addEventLog + params, eventLog)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.addEventLog + params, eventLog).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    updateProfileQuestionMeta(answer: any) {
        return new Promise((res, rej) => {
            var params = "";
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.updateProfileQuestionMeta + params, answer)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.updateProfileQuestionMeta + params, answer).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

            // let token = this.user.token;
            // let httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };
            // // var params = "?pp=" + programParticipantId;
            // var params = "";
            // this.http
            //     .post(this.domain + this.apis.updateProfileQuestionMeta + params, answer, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({
            //             success: true,
            //             data: r,
            //         });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    getContentQuestions(contentId: any, programParticipantId: any) {
        return new Promise((res, rej) => {
            var params = "?pp=" + programParticipantId;
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getContentQuestions + contentId + params)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getContentQuestions + contentId + params).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

            // let token: string = this.user.token;
            // var httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };
            // var params = "?pp=" + programParticipantId;
            // this.http
            //     .get(this.domain + this.apis.getContentQuestions + contentId + params, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    updateContentQuestionMeta(answer: any) {
        return new Promise((res, rej) => {
            var params = "";
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.updateContentQuestionMeta + params, answer)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.updateContentQuestionMeta + params, answer).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

            // let token = this.user.token;
            // let httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };
            // // var params = "?pp=" + programParticipantId;
            // var params = "";
            // this.http
            //     .post(this.domain + this.apis.updateContentQuestionMeta + params, answer, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({
            //             success: true,
            //             data: r,
            //         });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    submitContentQuestionMetaForReview(id: any) {
        return new Promise((res, rej) => {
            var params = "";
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.submitContentQuestionMetaForReview + id)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.submitContentQuestionMetaForReview + id).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });
    }

    //Playlists
    getPlaylists() {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getPlaylists)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getPlaylists).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    getPlaylistContent(id: number) {

        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                var params = "alias=" + environment.category;
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getPlaylistContents + id + "?" + params)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getPlaylistContents + id + "?" + params).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });
    }

    libraryContentCompleted(id: number) {

        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.libraryContentCompleted + id)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.libraryContentCompleted + id).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });
    }


    //Contemplations
    getContemplations() {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getGuidedContemplations + environment.category)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getGuidedContemplations + environment.category).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    getContemplation(id: number) {

        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                var params = "alias=" + environment.category;
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getGuidedContemplation + id + "?" + params)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getGuidedContemplation + id + "?" + params).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });
    }

    contemplationCompleted(id: number) {

        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.contemplationCompleted + id)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.contemplationCompleted + id).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });
    }

    //Feed
    getPublicFeed(param) {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                var category = "&category=" + environment.category;
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getPublicFeed + "?pageNumber=" + param.currentPage + "&pageSize=" + param.pageSize + category)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getPublicFeed + "?pageNumber=" + param.currentPage + "&pageSize=" + param.pageSize + category).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }
    getPersonalFeed(param) {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                var category = "&category=" + environment.category;
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getPersonalFeed + "?pageNumber=" + param.currentPage + "&pageSize=" + param.pageSize + category)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getPersonalFeed + "?pageNumber=" + param.currentPage + "&pageSize=" + param.pageSize + category).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    getPost(id: number) {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getPost + id)
                        .then((r: any) => {
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getPost + id).then((r: any) => {
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });
    }

    likePost(id: number) {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.likePost + id)
                        .then((r: any) => {
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.likePost + id).then((r: any) => {
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });
    }

    createPost(data: any) {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.savePost, data)
                        .then((r: any) => {
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.savePost, data).then((r: any) => {
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });
    }

    deletePost(id: number) {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.deleteDataFromUrl(this.domain + this.apis.deletePost + id)
                        .then((r: any) => {
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.deleteDataFromUrl(this.domain + this.apis.deletePost + id).then((r: any) => {
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });
    }

    getComments(id: number) {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getComments + id)
                        .then((r: any) => {
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getComments + id).then((r: any) => {
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });
    }

    createComment(data: any) {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.saveComment, data)
                        .then((r: any) => {
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.saveComment, data).then((r: any) => {
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });
    }

    deleteComment(id: number) {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.deleteDataFromUrl(this.domain + this.apis.deleteComment + id)
                        .then((r: any) => {
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.deleteDataFromUrl(this.domain + this.apis.deleteComment + id).then((r: any) => {
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });
    }

    //Conversation 
    getConversationGroup(id: number) {

        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getConversation + id)
                        .then((r: any) => {
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getConversation + id).then((r: any) => {
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });

    }

    approveSubmissionAsReviewed(messageId: any) {

        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.approveSubmission + messageId)
                        .then((r: any) => {
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.approveSubmission + messageId).then((r: any) => {
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });

    }

    sendMessage(last_message: string, message: any) {

        var params = "?lm=" + last_message;
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.postDataToUrl(this.domain + this.apis.sendMessage + params, message)
                        .then((r: any) => {
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.postDataToUrl(this.domain + this.apis.sendMessage + params, message).then((r: any) => {
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });

    }

    getMessages(id: number, first_message?: string, last_message?: string) {

        var params = "?";
        if (first_message) {
            params += "fm=" + first_message + "&";
        }
        if (last_message) {
            params += "lm=" + last_message;
        }

        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getMessages + id + params)
                        .then((r: any) => {
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getMessages + id + params).then((r: any) => {
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });

    }

    updateLastReadOn(id: number) {

        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.updateLastReadOn + id)
                        .then((r: any) => {
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.updateLastReadOn + id).then((r: any) => {
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

        });

    }


    //Category Membership
    checkCategoryMembership(category: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.checkCategoryMembership + category, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getCategoryMembership(category: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getCategoryMembership + category, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getCategorySubscription(category: any, offerId: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getCategorySubscription + offerId + "?category=" + category, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    subscribeMembership(offerId: any, categoryAlias: any, organisationId?: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            var params = "";
            if (organisationId) {
                params = "&org=" + organisationId;
            }

            this.http
                .get(this.domain + this.apis.subscribeMembership + offerId + "?category=" + categoryAlias + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    subscribeMembershipV2(offerId: any, categoryAlias: any, organisationId?: number) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            var params = "";
            if (organisationId) {
                params = "&org=" + organisationId;
            }

            this.http
                .get(this.domain + this.apis.subscribeToMembership + offerId + "?category=" + categoryAlias + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getCategoryProgram(category: any, program: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getCategoryProgram + program, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getLiveCohorts(category: any) {
        return new Promise((res, rej) => {

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getLiveCohorts + category)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getLiveCohorts + category).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }


    getCategoryContentV2(category: any) {
        return new Promise((res, rej) => {

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getCategoryContentV2 + category)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getCategoryContentV2 + category).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    getCategoryContentV3(category: any) {
        return new Promise((res, rej) => {

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getCategoryContentV3 + category)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getCategoryContentV3 + category).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    startFreeTrial() {
        return new Promise((res, rej) => {
            var params = "";
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.startTrial + params)
                        .then((r: any) => {
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.startTrial + params).then((r: any) => {
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });
                }
            });
        });
    }

    startProgram(alias: any) {
        return new Promise((res, rej) => {
            var params = "";
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.startProgram + alias)
                        .then((r: any) => {
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.startProgram + alias).then((r: any) => {
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });
                }
            });
        });
    }

    subscribeToPlan(price_id) {
        return new Promise((res, rej) => {

            var params = "";
            var param = []; if (param.length > 0) {
                params = "?" + param.join('&');
            }

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.subscribeToPlan + price_id)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.subscribeToPlan + price_id).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    getPlans() {
        return new Promise((res, rej) => {

            var params = "";
            var param = []; if (param.length > 0) {
                params = "?" + param.join('&');
            }

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getPlans)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getPlans).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    getProgramDetailsV2(alias: any, participantId?: any, batchId?: any) {
        return new Promise((res, rej) => {

            var params = "";
            var param = [];
            if (participantId) {
                param.push("ppId=" + participantId);
            }
            if (batchId) {
                param.push("bId=" + batchId);
            }

            if (param.length > 0) {
                params = "?" + param.join('&');
            }

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getProgramDetailsV2 + alias + params)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getProgramDetailsV2 + alias + params).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    getContentsV2(alias: any, batchId?: any, programId?: any, programParticipantId?: any) {
        return new Promise((res, rej) => {

            var params = "";
            var param = [];
            if (programParticipantId) {
                param.push("ppId=" + programParticipantId);
            }
            if (programId) {
                param.push("pId=" + programId);
            }
            if (batchId) {
                param.push("bId=" + batchId);
            }

            if (param.length > 0) {
                params = "?" + param.join('&');
            }

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getContentListV2 + alias + params)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getContentListV2 + alias + params).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    getCategoryContent(category: any) {
        return new Promise((res, rej) => {

            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getCategoryContent + category)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getCategoryContent + category).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });

            // let token: string = this.user.token;
            // var httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };
            // this.http
            //     .get(this.domain + this.apis.getCategoryContent + category, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    getCategoryUpcomingCohorts(category: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getCategoryUpcomingCohorts + category, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getCategoryWorkspace(category: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getCategoryWorkspace + category, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getProfileGroups(category: any) {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getProfileGroups + category)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getProfileGroups + category).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
            // let token: string = this.user.token;
            // var httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };
            // this.http
            //     .get(this.domain + this.apis.getProfileGroups + category, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }


    checkPendingInvites(category: any) {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getPendingInvites)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getPendingInvites).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
            // let token: string = this.user.token;
            // var httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };
            // this.http
            //     .get(this.domain + this.apis.getProfileGroups + category, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    markIntroduced() {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.markIntroduced)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.markIntroduced).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
            // let token: string = this.user.token;
            // var httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };
            // this.http
            //     .get(this.domain + this.apis.getProfileGroups + category, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    agreeTerms() {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.agreeTerms)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.agreeTerms).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
        });
    }

    markAdioscopeAcknowledged() {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.acknowledgeAdioscope)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.acknowledgeAdioscope).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
            // let token: string = this.user.token;
            // var httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };
            // this.http
            //     .get(this.domain + this.apis.getProfileGroups + category, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    markOrganisationIntroduced(id: any) {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.markOrganisationIntroduced + id)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.markOrganisationIntroduced + id).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
            // let token: string = this.user.token;
            // var httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };
            // this.http
            //     .get(this.domain + this.apis.getProfileGroups + category, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    getIntroProgram() {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getIntroProgram)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getIntroProgram).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
            // let token: string = this.user.token;
            // var httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };
            // this.http
            //     .get(this.domain + this.apis.getProfileGroups + category, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    getToday(category: any) {
        return new Promise((res, rej) => {
            this.common.canUseNative().then((isNative: boolean) => {
                if (isNative) {
                    this.nativeapi.getDataFromUrl(this.domain + this.apis.getCategoryToday + category)
                        .then((r: any) => {
                            // this.user.setToken(r.Auth.Token);
                            res({
                                success: true,
                                data: r,
                            });
                        }).catch(e => {
                            let msg = undefined;
                            if (typeof e.error === 'string') {
                                msg = e.error;
                            }
                            rej({ success: false, data: e, msg });
                        });
                } else {
                    this.webapi.getDataFromUrl(this.domain + this.apis.getCategoryToday + category).then((r: any) => {
                        // this.user.setToken(r.Auth.Token);
                        res({
                            success: true,
                            data: r,
                        });
                    }).catch(e => {
                        let msg = undefined;
                        if (typeof e.error === 'string') {
                            msg = e.error;
                        }
                        rej({ success: false, data: e, msg });
                    });

                }
            });
            // let token: string = this.user.token;
            // var httpOptions = {
            //     headers: new HttpHeaders({
            //         'Content-Type': 'application/json',
            //         Token: token
            //     }),
            // };
            // this.http
            //     .get(this.domain + this.apis.getProfileGroups + category, httpOptions)
            //     .toPromise()
            //     .then((r: any) => {
            //         res({ success: true, data: r });
            //     })
            //     .catch(e => {
            //         ////console.log('Error in get', e);
            //         if (e.status == 401) {
            //             this.logout();
            //             this.router.navigateByUrl('');
            //         }
            //         let msg = undefined;
            //         if (typeof e.error === 'string') {
            //             msg = e.error;
            //         }
            //         rej({ success: false, data: e, msg });
            //     });
        });
    }

    joinCategoryBatch(batchId: any, trial?: boolean) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };

            var params = "?";
            var param: any = [];

            if (trial) {
                param.push("trial=" + trial);
            }
            if (param.length > 0) {
                params = "?" + param.join("&");
            }

            this.http
                .get(this.domain + this.apis.joinCategoryBatch + batchId + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    getOrganisationMembership(organisationId: any) {
        return new Promise((res, rej) => {
            let token: string = this.user.token;
            var httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            this.http
                .get(this.domain + this.apis.getOrganisationMembership + organisationId, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({ success: true, data: r });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }


    // Admin Apis
    getProgramsList() {
        return new Promise((res, rej) => {
            let token = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            // var params = "?p=" + programId + "&b=" + batchId + "&pp=" + programParticipantId;
            var params = "";
            this.http
                .get(this.domain + this.apis.getProgramList + params, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }

    addBatch(batch: any) {
        return new Promise((res, rej) => {
            let token = this.user.token;
            let httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Token: token
                }),
            };
            // var params = "?p=" + programId + "&b=" + batchId + "&pp=" + programParticipantId;
            var params = "";
            this.http
                .post(this.domain + this.apis.addBatch + params, batch, httpOptions)
                .toPromise()
                .then((r: any) => {
                    res({
                        success: true,
                        data: r,
                    });
                })
                .catch(e => {
                    ////console.log('Error in get', e);
                    if (e.status == 401) {
                        this.logout();
                        this.router.navigateByUrl('');
                    }
                    let msg = undefined;
                    if (typeof e.error === 'string') {
                        msg = e.error;
                    }
                    rej({ success: false, data: e, msg });
                });
        });
    }



    //Utilities
    public getDateOffset = () => {
        var offset = new Date().getTimezoneOffset();
        console.log(offset);
        var dateOffset;
        if (offset > 0) {
            dateOffset = "-" + ('0' + Math.floor((offset / 60))).slice(-2) + "" + ('0' + Math.floor((offset % 60)).toFixed(0)).slice(-2);
        } else {
            dateOffset = "+" + ('0' + Math.floor(((offset * -1) / 60)).toFixed(0)).slice(-2) + "" + ('0' + Math.floor(((offset * -1) % 60)).toFixed(0)).slice(-2);
        }

        return dateOffset;
    };

    public getCurrency = () => {
        var offset = new Date().getTimezoneOffset().valueOf();
        if (offset == -330) {
            return "INR";
        } else {
            return "USD";
        }
    }

    updateTitle(title: string) {
        // this.title.setTitle(title);
        // this.updateOgTitle(title);
    }

    updateOgTitle(value: string) {
        // this.meta.updateTag({ name: 'og:title', content: value });
    }
    updateOgType(value: string) {
        this.meta.updateTag({ name: 'og:type', content: value });
    }
    updateOgDescription(value: string) {
        this.meta.updateTag({ name: 'og:description', content: value });
    }
    updateOgImage(value: string) {
        this.meta.updateTag({ name: 'og:image', content: value });
    }
    updateOgSiteName(value: string) {
        this.meta.updateTag({ name: 'og:site_name', content: value });
    }
    updateOgUrl(url: string) {
        this.meta.updateTag({ name: 'og:url', content: url })
    }

    updateRobots(value: string) {
        this.meta.updateTag({ name: 'robots', content: value })
    }

    updateDescription(desc: string) {
        this.meta.updateTag({ name: 'description', content: desc });
        this.updateOgDescription(desc);
    }

}
