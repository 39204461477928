import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/shared/services/api.service';
import { ExerciseLogReportComponent } from '../exercise-log-report/exercise-log-report.component';

@Component({
    selector: 'sb-show-exercise-logs-modal',
    templateUrl: './show-exercise-logs-modal.component.html',
    styleUrls: ['./show-exercise-logs-modal.component.scss']
})
export class ShowExerciseLogsModalComponent implements OnInit {

    @ViewChild(ExerciseLogReportComponent) exerciseLogReport!: ExerciseLogReportComponent;

    @Input() participantId?: number;
    @Input() participant?: any;

    loading = false;
    participants: any[] = [];
    selectedParticipantId?: any[] = [];

    exerciseLogs: any[] = [];

    constructor(
        private modalController: ModalController,
        private apiService: ApiService,
        private snackbar: MatSnackBar,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef
    ) { }

    ngOnInit() {
        if (this.participant) {
            this.selectedParticipantId = [this.participant.UserId];
        } else {
            this.loadParticipants();
        }
        // this.loadExerciseLogs();
    }

    async loadParticipants() {
        this.loading = true;
        this.apiService.getTrainerParticipantsList().then((response: any) => {
            this.participants = response.data;
            this.loading = false;
        }).catch((error: any) => {
            this.snackbar.open('Error loading participants', 'Close', { duration: 3000 });
            this.loading = false;
        });
    }

    // async loadExerciseLogs() {
    //     this.apiService.getTrainerExerciseSummaryForLast7Days(this.selectedParticipantId).then((response: any) => {
    //         this.exerciseLogs = response.data;
    //     }).catch((error: any) => {
    //         this.snackbar.open('Error loading grading assignments', 'Close', { duration: 3000 });
    //     });
    // }

    onParticipantChange($event: any) {
        this.changeDetectorRef.detectChanges();
        this.exerciseLogReport.participant = this.selectedParticipantId;
        this.exerciseLogReport.ngOnInit();
    }

    selectAllParticipants() {
        if (this.selectedParticipantId.length === this.participants.length) {
            this.selectedParticipantId = [];
        } else {
            this.selectedParticipantId = [0, ...this.participants.map(p => p.Id)];
        }
    }

    back() {
        this.modalController.dismiss();
    }
} 